<template>
  <base-map
    :ref="`baseMap-${this.name}`"
    :center="center"
    :zoom="zoom"
    @update:center="$emit('update:center', $event)"
    @update:zoom="$emit('update:zoom', $event)"
    @click="
      $emit('click', $event)
      selectSentry(null)
      onMapClick()
    "
    editable
    :name="name"
    :searchBar="searchBar"
    :measureEnabled="measureEnabled"
    :disabled="disabled"
    :class="{ exporting: exporting && name === 'wizardMapPDF', planMap: true }"
    :layerSwitch="!exporting"
    :zoomControl="!exporting"
    :suppressModeWarning="true"
  >
    <sentry-marker
      v-if="activeInstallation && name !== 'wizardMapPDF'"
      :key="`active-sentry-marker-${activeInstallation.id}`"
      :coords="activeInstallation.coordinates"
      :draggable="true"
      :icon-size="iconSize"
      :sentry="activeInstallation"
      :active-sentry-id="activeInstallation.id"
      @moveend="onMoveInstallation"
      :shadow="true"
    />

    <intersection-coverage
      v-if="loadedPlan"
      :visible="!activeInstallation"
      :sensors="rf_sensorsSet"
    />

    <site-marker v-if="siteCenter" :center="siteCenter" />

    <LFeatureGroup ref="coverage-features" @ready="featuresReady" :key="planIdentifier">
    <template v-for="item in installations">
      <sentry-marker
        v-if="!activeInstallation || item.id !== activeInstallation.id"
        :active-sentry-id="activeSentryId"
        :key="`sentry-marker-${item.id}`"
        :coords="item.coordinates"
        :icon-size="iconSize"
        :sentry="item"
        :shadow="true"
        @selectSentry="
          name !== 'wizardMapPDF' && draggableSensors && selectSentry($event)
        "
        @update:latLng="$emit('update:latLng', $event)"
        :draggable="draggableSensors && item.id === activeSentryId"
      />
        <template v-if="item.sensors">
          <template v-if="item.sensors.rf_sensors">
            <rf-sector-coverage
                v-for="sensor in item.sensors.rf_sensors"
                :key="`rf-${item.id}-${sensor.id}-${draggableSensors}`"
                :visible="true"
                :draggable="draggableSensors"
                :cancel-drag-handler="cancelDragHandler"
                :sensor="{
              ...sensor,
              longitude: item.coordinates[1],
              latitude: item.coordinates[0]
            }"
            />
          </template>
          <template v-if="item.sensors.dsx_sensors">
            <dronesentryx-sector-coverage
                v-for="sensor in item.sensors.dsx_sensors"
                :key="`dsx-${item.id}-${sensor.id}-${draggableSensors}`"
                :visible="true"
                :draggable="draggableSensors"
                :cancel-drag-handler="cancelDragHandler"
                :sensor="{
              ...sensor,
              longitude: item.coordinates[1],
              latitude: item.coordinates[0]
            }"
            />
          </template>
          <template v-if="item.sensors.discovair_sensors">
            <discovair-sector-coverage
                v-for="sensor in item.sensors.discovair_sensors"
                :key="`discovair-${item.id}-${sensor.id}-${draggableSensors}`"
                :visible="true"
                :draggable="draggableSensors"
                :cancel-drag-handler="cancelDragHandler"
                :sensor="sensor"
            />
          </template>
          <template v-if="item.sensors.radars">
            <radar-sector-coverage
                v-for="sensor in item.sensors.radars"
                :key="`radars-${item.id}-${sensor.id}-${draggableSensors}`"
                :visible="true"
                :draggable="draggableSensors"
                :cancel-drag-handler="cancelDragHandler"
                :sensor="{
              ...sensor,
              longitude: item.coordinates[1],
              latitude: item.coordinates[0]
            }"
            />
          </template>
          <template v-if="item.sensors.cannons">
            <cannon-sector-coverage
                v-for="sensor in item.sensors.cannons"
                :key="`cannons-${item.id}-${sensor.id}-${draggableSensors}`"
                :visible="true"
                :draggable="draggableSensors"
                :cancel-drag-handler="cancelDragHandler"
                :sensor="{
              ...sensor,
              longitude: item.coordinates[1],
              latitude: item.coordinates[0]
            }"
            />
          </template>
          <template v-if="item.sensors.cameras">
            <camera-sector-coverage
                v-for="sensor in item.sensors.cameras"
                :key="`cameras-${item.id}-${sensor.id}-${draggableSensors}`"
                :visible="true"
                :draggable="draggableSensors"
                :sensor="{
              ...sensor,
              longitude: item.coordinates[1],
              latitude: item.coordinates[0],
              forcedReach: 1000
            }"
            />
          </template>
        </template>
    </template>
    </LFeatureGroup>
    <template>
      <l-polygon-tooltip
        v-for="(z, i) in allZones"
        @click="$bus.$emit('activateZone', z.id)"
        :visible="z.id !== activeZone.id || !zoneEditMode"
        :key="`z-${i}-${activeZone.id}`"
        :lat-lngs="z.coordinate_list"
        color="yellow"
        :weight="3"
        fill-color="yellow"
        :fill-opacity="z.id === activeZone.id ? 0.2 : 0.1"
        dash-array="10, 15"
        line-cap="square"
        :opacity="0.5"
        :tooltip="z.name"
        :hoverProperties="{ opacity: 0.7 }"
      />

      <editable-polygon
        v-if="zoneEditMode"
        :editable="zoneEditMode"
        @editable:vertex:dragend="onShapeChange"
        :lat-lngs="activeZone.coordinate_list"
        color="yellow"
        :fill-opacity="0.1"
        :weight="3"
        fill-color="yellow"
      />
    </template>
    <template v-if="!draggableSensors">
      <template v-if="viewShedAreas.length > 0">
        <polygon-fog-of-war
          v-for="(z, i) in viewShedAreas"
          :key="`vs-${i}`"
          :lat-lngs="z"
          :opacity="0"
          :fill-opacity="0"
          fill-color="black"
          mask="fogOfWar"
        />
        <polygon-fog-of-war
          :fill-opacity="0"
          :mask-opacity="0.7"
          fill-color="black"
          :opacity="0"
          mask-id="fogOfWar"
        />
      </template>
      <l-polygon-tooltip
        v-for="(z, i) in rfPerfIsobands"
        :visible="rfPerfIsobands.length > 0"
        :lat-lngs="z.geometry.coordinates"
        :key="`rfperf-${i}`"
        :tooltip="
          i === rfPerfIsobands.length - 1
            ? 'Intersection'
            : `${accuracyLabels[i]} Accuracy`
        "
        :hoverProperties="{ opacity: 0.3 }"
        :fill-opacity="0.7 - (i + 1) / (2 * rfPerfIsobands.length)"
        fill-color="#d1deec"
        color="white"
        :weight="2"
        :opacity="0"
      />
    </template>
  </base-map>
</template>

<script>
import Vue from 'vue'
import { mapGetters, mapActions, mapState } from 'vuex'
import BaseMap from './BaseMap'
import { SiteMarker } from './Marker'
import { EditablePolygon } from 'vue2-leaflet-editable'
import { SentryMarker } from '@/components/Map/Marker'
import PolygonTooltip from 'vue2-leaflet-polygontooltip'
Vue.use(PolygonTooltip)
import { PolygonFogOfWar } from 'vue2-leaflet-fogofwar'
import {
  RfSectorCoverage,
  RadarSectorCoverage,
  CameraSectorCoverage,
  CannonSectorCoverage,
  DiscovairSectorCoverage,
  IntersectionCoverage,
  DronesentryxSectorCoverage
} from '@/components/Map/Coverage'
import isobands from '@turf/isobands'
import {featureCollection, point} from '@turf/helpers'
import get from 'lodash/get'
import { LFeatureGroup } from 'vue2-leaflet'


const props = {
  shape: {
    type: Array
  },
  center: {
    type: Array,
    default: () => [0, 0]
  },
  zoom: {
    type: Number,
    default: 14
  },
  zoneEditMode: {
    type: Boolean,
    default: false
  },
  searchBar: {
    type: Boolean,
    default: true
  },
  activeZone: Object,
  name: String,
  disabled: {
    type: Boolean,
    default: false
  },
  plan: {
    type: Object,
    default: () => {}
  },
  draggableSensors: {
    type: Boolean,
    default: false
  },
  measureEnabled: {
    type: Boolean,
    default: true
  },
  active: {
    type: Boolean,
    default: true
  },
  cancelDragHandler: {
    type: Boolean,
    default: false
  }
}

const DEBUG = false

export default {
  name: 'PlanMap',
  components: {
    BaseMap,
    EditablePolygon,
    SentryMarker,
    RfSectorCoverage,
    RadarSectorCoverage,
    DiscovairSectorCoverage,
    DronesentryxSectorCoverage,
    CameraSectorCoverage,
    CannonSectorCoverage,
    IntersectionCoverage,
    PolygonFogOfWar,
    SiteMarker,
    LFeatureGroup
  },
  props,
  model: {
    prop: 'shape',
    event: 'change'
  },
  mounted() {
    this.$bus.$on('viewshed', areas => {
      this.viewShedAreas = areas
    })
    this.$emitter.on('rfPerfUpdated', this.rfPerfGridUpdate)
    this.$bus.$on('dragEnd', ({ name, event, sensorId, sensorName }) => {
      if (name !== this.name) return
      const droppedSentryImg = document.querySelector('.sentry-marker:hover')
      const dSentryClass = get(droppedSentryImg, 'className', '')
      const sentryId = get(dSentryClass.match(/sentry-id-(\d+)/), 1)
      const coordinates = this.eventToLatLng(event)
      this.$emit('setPlanSensor', {
        sensorId,
        ...coordinates,
        sentryId,
        sensorName
      })
    })
    this.$emitter.on('centerPlanMap', this.centerPlanMap)
  },
  beforeDestroy() {
    this.$bus.$off('viewshed')
    this.$bus.$off('dragEnd')
    this.$emitter.off('centerPlanMap', this.centerPlanMap)
    this.$emitter.on('rfPerfUpdated', this.rfPerfGridUpdate)
  },
  data: () => ({
    viewShedAreas: [],
    rfPerfGrid: [],
    accuracyLabels: ['Very High', 'High', 'Better'],
    lastDragCenter: null
  }),
  computed: {
    ...mapGetters('plans', ['activeInstallation', 'activePlan']),
    ...mapGetters('sentries', ['activeSentryId']),
    ...mapGetters('maps', ['exporting']),
    ...mapState('selection', {
      activeSentries: 'activeSentries',
      activeSensors: 'activeSensors',
      activeGroups: 'activeGroups',
      activeCamera: 'activeCamera'
    }),
    ...mapState('sensors', ['rfBbox']),
    mapObject() {
      return this.$refs[`baseMap-${this.name}`].mapObject
    },
    planIdentifier() {
      if (this.loadedPlan === null) return 'no-plan'
      return `${this.loadedPlan.id}-${this.loadedPlan.name}`
    },
    loadedPlan() {
      return this.plan || this.activePlan
    },
    installations() {
      return get(this.loadedPlan, 'site_plan.installations', [])
    },
    siteCenter() {
      return get(this.loadedPlan, 'site_plan.coordinates')
    },
    rf_sensorsSet() {
      return this.installations
        .filter(v => v.sensors && v.sensors.rf_sensors)
        .map(v => v.sensors.rf_sensors)
        .flat()
        .filter(sensor =>
          this.installations.some(sentry => sentry.id == sensor.sentry_id)
        )
        .reduce((map, obj) => {
          map[obj.id + '-' + obj.sentry_id] = obj
          return map
        }, {})
    },
    allZones() {
      return get(this.loadedPlan, 'site_plan.zones', [])
    },
    iconSize() {
      return [40, 40]
    },

    rfPerfIsobands() {
      return this.rfPerfGrid.length && this.getIsoBands() || []
    }
  },
  methods: {
    ...mapActions('sentries', { selectSentry: 'SELECT_SENTRY' }),
    ...mapActions('plans', {
      activateInstallation: 'ACTIVATE_INSTALLATION'
    }),
    ...mapActions('selection', [
      'clearSentries',
      'clearSensors',
      'clearGroups',
      'setActiveCamera'
    ]),
    rfPerfGridUpdate(grid) {
      this.rfPerfGrid = grid
    },
    centerPlanMap({coordinates, zoom}) {
      if (!coordinates || coordinates[0] === undefined || coordinates[1] === undefined) return
      if (this.mapObject) {
        this.mapObject.flyTo(coordinates, zoom, {
          animate: true
        })
      }
      this.$store.commit('sensors/UPDATE_RF_PERF_ESTIMATION', {data: null})
    },
    getIsoBands() {
      const isobandList = isobands(
          featureCollection(
              this.rfPerfGrid
              .flat()
              .filter(r => r)
              .map(r => point([r.lat, r.lng], { score: r.score || 99999 }))
          ),
          [0, 50, 100, 200, 99998],
          { zProperty: 'score' }
      ).features
      return isobandList
    },
    featuresReady() {
      const featureBounds = this.$refs['coverage-features'].mapObject.getBounds()

      if (featureBounds.isValid()) {
        if (DEBUG) {
          const rectangle = L.rectangle(featureBounds, {
            color: '#3388ff', weight: 1
          })
          rectangle.addTo(this.$refs[`baseMap-${this.name}`].mapObject)
        }

        const bounds = {
          top_left_lat: featureBounds.getNorthWest().lat,
          top_left_lng: featureBounds.getNorthWest().lng,
          bottom_right_lat: featureBounds.getSouthEast().lat,
          bottom_right_lng: featureBounds.getSouthEast().lng,
        }
        this.$store.dispatch('plans/setBounds', bounds)
      }
    },
    showCoverageSector(sensor, type) {
      let status =
        !!this.activeGroups.find(item => {
          return item.sentry === sensor.sentry_id && item.group === type
        }) && this.activeSentries.includes(sensor.sentry_id)
      if (type === 'cameras') {
        if (this.activeCamera) {
          status = status && sensor.id === this.activeCamera.id
        }
      }
      return status
    },
    async onMapClick() {
      await this.clearSensors()
      await this.setActiveCamera(null)
      await this.clearGroups()
      await this.clearSentries()
    },
    eventToLatLng(e) {
      return this.$refs[`baseMap-${this.name}`].mapObject.mouseEventToLatLng(e)
    },
    onMoveInstallation(e) {
      let installation = this.activeInstallation
      installation.coordinates = [e.target._latlng.lat, e.target._latlng.lng]
      this.activateInstallation(installation)
      this.$bus.$emit('movedInstallation')
    },
    onShapeChange(e) {
      const {
        target: { _latlngs }
      } = e
      const [coords = []] = _latlngs || []
      this.$emit(
        'change',
        coords.map(e => [e.lat, e.lng])
      )
    },
    invalidateMap() {
      this.$refs[`baseMap-${this.name}`] &&
        this.$refs[`baseMap-${this.name}`].invalidateSize()
    }
  },
  watch: {
    active(v) {
      if (!v) this.selectSentry(null)
    },
    exporting(v) {
      if (this.name === 'wizardMapPDF') {
        document.querySelector(
          '.leaflet-control-container'
        ).style.visibility = v ? 'hidden' : 'visible'
        this.$nextTick(() => this.invalidateMap())
      }
    },
    planIdentifier: {
      handler: function(identifier) {
        if (identifier === 'no-plan') {
          this.rfPerfGrid = []
        }
      }
    }
  }
}
</script>
<style>
.exporting {
  position: fixed !important;
  width: 1870px !important;
  height: 2100px !important;
  /* filter: opacity(0%); */
  pointer-events: none;
}
</style>
