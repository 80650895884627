<template>
  <v-expansion-panel
    class="elevation-0"
    id="advancedAnalysis"
    v-if="nbRfSensors > 1 && nbInstallations > 1"
  >
    <v-expansion-panel-content style="background-color: transparent">
      <v-subheader class="pa-0 grey--text" slot="header"
        >Advanced Analysis</v-subheader
      >
      <v-flex text-xs-center class="ma-0 pa-0">
        <v-btn
          :color="rfPerfButtonColor"
          outline
          class="caption mt-0 mb-3"
          @click="startAnalysis('rfperf')"
          :loading="timers.rfperf != null"
          :disabled="lastComputed.rfperf && lastComputed.rfperf.length === 0"
        >
          <v-icon left small>leak_add</v-icon>
          {{
            rfPerfButtonLabel
          }}
          <template v-slot:loader>
            <v-progress-circular
              indeterminate
              color="primary"
              class="mr-2"
              :size="16"
            ></v-progress-circular>
            <span>
              {{ loadingMessages.rfperf[loadingIndex.rfperf] + '...' }}
            </span>
          </template>
        </v-btn>
      </v-flex>
    </v-expansion-panel-content>
  </v-expansion-panel>
</template>
<script>
import viewShed from '@/utils/viewShed'
import mapUtils from '@/components/Map/utils'

import bbox from '@turf/bbox'
import union from '@turf/union'
import { polygon } from '@turf/helpers'
import { mapGetters, mapState } from 'vuex'

export default {
  name: 'RfPerformance',
  data: () => ({
    timers: {
      rfperf: null,
      viewshed: null
    },
    lastComputed: {
      rfperf: null,
      viewshed: null
    },
    activeLayer: {
      rfperf: false,
      viewshed: false
    },
    loadingIndex: {
      rfperf: 0,
      viewshed: 0
    },
    loadingMessages: {
      viewshed: [
        'Initializing',
        'Getting elevation',
        'Ray tracing',
        'Calculating area',
        'Loading'
      ],
      rfperf: [
        'Initializing',
        'Estimating',
        'Loading',
        '',
        'Loading',
        '',
        'Loading'
      ]
    }
  }),
  computed: {
    ...mapGetters('plans', ['activePlan']),
    ...mapState('sensors', ['rfPerfGrid']),
    ...mapState('plans', ['status', 'bounds']),
    activeLayerRfPerf() {
      return this.activeLayer['rfperf']
    },
    rfPerfButtonLabel() {
      if (this.lastComputed.rfperf && this.lastComputed.rfperf.length === 0)
        return 'RF Analysis Failed'

      return `${this.activeLayerRfPerf ? 'Hide' : 'Show'} RF Intersection Perf.`
    },
    rfPerfButtonColor() {
      return this.activeLayerRfPerf ? 'white' : 'primary'
    },
    nbInstallations() {
      return (
        this.activePlan &&
        this.activePlan.site_plan &&
        this.activePlan.site_plan.installations &&
        this.activePlan.site_plan.installations.length
      )
    },
    nbRfSensors() {
      return (
        this.activePlan &&
        this.activePlan.site_plan &&
        this.activePlan.site_plan.installations &&
        this.activePlan.site_plan.installations.reduce(
          (s, i) =>
            (s +=
              (i.sensors &&
                i.sensors.rf_sensors &&
                i.sensors.rf_sensors.filter(rf => rf.model !== 'rf_zero')
                  .length) ||
              0),
          0
        )
      )
    }
  },
  methods: {
    sendRfData(data) {
      this.$emitter.emit('plotRfData', data)
    },
    async startAnalysis(type) {
      if (this.activeLayerRfPerf) {
        this.resetAnalysis('viewshed')
        this.resetAnalysis('rfperf')
        this.$store.commit('sensors/UPDATE_RF_PERF_ESTIMATION', {data: null})
      } else {
        if (this.lastComputed[type]) {
          this.$bus.$emit(type, this.activeLayer[type] ? [] : this.lastComputed[type])
          this.activeLayer = Object.assign({}, this.activeLayer, {
            [type]: !this.activeLayer[type]
          })
          return
        }
        this.timers = Object.assign({}, this.timers, {[type]: 1})
        this.loadingIndex[type] = 0

        // Retrieve all rf sensors with viewshed attributes
        let rf_sensors_viewshed = []
        let rf_sensors = []
        for (let installation of this.activePlan.site_plan.installations) {
          if (installation.sensors && installation.sensors.rf_sensors) {
            for (let rf_sensor of installation.sensors.rf_sensors) {
              if (rf_sensor.model !== 'rf_zero') {
                if (type === 'viewshed') {
                  rf_sensors_viewshed.push({
                    geometry: {
                      x: rf_sensor.longitude, y: rf_sensor.latitude
                    }, attributes: {
                      OFFSETA: rf_sensor.agl || 10,
                      OFFSETB: 10,
                      AZIMUTH1: (360 + rf_sensor.direction - 45) % 360,
                      AZIMUTH2: (360 + rf_sensor.direction + 45) % 360,
                      VERT1: 90,
                      VERT2: -90
                    }
                  })
                } else
                  if (type === 'rfperf') {
                    rf_sensors.push({
                      name: `${rf_sensor.id}${rf_sensor.sentry_id}`,
                      lat: rf_sensor.latitude,
                      lng: rf_sensor.longitude,
                      angle: 90,
                      reach: rf_sensor.reach,
                      direction: rf_sensor.direction
                    })
                  }
              }
            }
          }
        }

        if (type === 'rfperf') {
          const data = {
            rf_sensors: JSON.stringify(rf_sensors), sampling_density: 'low', ...this.bounds
          }

          this.$store.dispatch('sensors/RF_PERF_ESTIMATION', data)

          const stepDuration = Math.max(2, (3.36 * rf_sensors.length) / 7)
          this.loadingMessages[type][this.loadingMessages[type].length - 4] = `About ${Math.round((3.36 * rf_sensors.length - 3 * stepDuration) / 6) / 10} min remaining`
          this.loadingMessages[type][this.loadingMessages[type].length - 2] = `${rf_sensors.length} sensors processing`
          this.timers[type] = setInterval(async () => {
            if (this.loadingIndex[type] < this.loadingMessages[type].length - 1) this.loadingIndex = Object.assign({}, this.loadingIndex, {
              [type]: this.loadingIndex[type] + 1
            })
          }, stepDuration * 1000)
        } else
          if (type === 'viewshed') {
            let result = await viewShed.startAnalysis(rf_sensors_viewshed)
            if (result.jobStatus === 'esriJobSubmitted') {
              let jobId = result.jobId
              this.timers[type] = setInterval(async () => {
                if (this.loadingIndex[type] < this.loadingMessages[type].length - 1) this.loadingIndex = Object.assign({}, this.loadingIndex, {
                  [type]: this.loadingIndex[type] + 1
                })
                let status = await viewShed.checkStatus(jobId)
                if (status === 'esriJobSucceeded' || status === 'esriJobFailed') {
                  clearTimeout(this.timers[type])
                  let areas = status === 'esriJobSucceeded' ? await viewShed.getResult(jobId) : null
                  this.$bus.$emit(type, areas || [])
                  this.lastComputed[type] = areas
                  this.timers[type] = null
                  this.loadingIndex[type] = 0
                  this.activeLayer[type] = status === 'esriJobSucceeded'
                }
              }, 2500)
            }
          }
      }
    },
    resetAnalysis(type) {
      this.lastComputed[type] = null
      clearTimeout(this.timers[type])
      this.timers[type] = null
      this.activeLayer[type] = false
    }
  },
  watch: {
    rfPerfGrid(v) {
      clearTimeout(this.timers.rfperf)
      this.$emitter.emit('rfPerfUpdated', v || [])
      if (v.length > 0) {
        this.lastComputed.rfperf = v
        this.timers.rfperf = null
        this.loadingIndex.rfperf = 0
        this.activeLayer.rfperf = true
      }
    },
    activePlan() {
      this.resetAnalysis('viewshed')
      this.resetAnalysis('rfperf')
    },
    status(v) {
      if (v === 'LOADING') {
        this.resetAnalysis('viewshed')
        this.resetAnalysis('rfperf')
      }
    }
  },
  mounted() {
    this.$bus.$on('movedInstallation', () => this.resetAnalysis('rfperf'))
  },
  beforeDestroy() {
    this.$bus.$off('movedInstallation')
    Object.keys(this.timers).forEach(t => clearTimeout(this.timers[t]))
    this.$store.commit('sensors/UPDATE_RF_PERF_ESTIMATION', {data: null})
  }
}
</script>
<style>
#advancedAnalysis
  > li
  > div.v-expansion-panel__header
  > div.v-expansion-panel__header__icon
  > i {
  color: grey;
}
</style>
