<template>
  <v-layout row wrap>
    <v-flex xs12 id="wizardStep3Form">
      <p class="title">What detection sensors?</p>
      <v-alert class="mt-3 mb-3" :value="true" type="info" outline color="white"
        >Drag and drop sensors below to the map. <br />
        Click on each sensor for more information</v-alert
      >
      <p class="fadein">
        <v-layout row wrap>
          <v-flex
            xs6
            class="px-2 btn-container"
            v-for="(label, i) in draggableButtons"
            :key="`btn-drag-${i}`"
          >
            <v-btn
              draggable="true"
              block
              outline
              :color="draggingId == i ? 'orange dark' : 'primary'"
              class="btn-sensor"
              @click="addCard(label[0] + label[1])"
              @dragstart="dragStart($event, i)"
              @dragend="dragEnd($event, i)"
              ><span>{{ label[0] }}</span><span class="bold">{{ label[1] }}</span></v-btn
            >
          </v-flex>

          <v-flex xs12 class="mt-2">
            <v-tabs
              color="transparent"
              fixed-tabs
              v-model="activeTab"
              dark
              slider-color="primary"
              ref="tabs"
            >
              <v-tab ripple>Information</v-tab>
              <v-tab-item
                :class="`fadein ${droppedSomething ? '' : 'delay-05'} pt-2`"
              >
                <hint-card
                  class="expand"
                  v-for="cardKey in activeHintCards"
                  :key="`hintcard-${cardKey}`"
                  v-bind="cardsData[cardKey]"
                  @delete="deleteCard(cardKey)"
                />
              </v-tab-item>

              <v-tab ripple>Parts List</v-tab>
              <v-tab-item
                :class="`fadein ${droppedSomething ? '' : 'delay-05'} pt-2`"
              >
                <plan-parts-list
                  :plan="plan"
                  @setCNType="$emit('setCNType', $event)"
                />
              </v-tab-item>
            </v-tabs>
          </v-flex>
        </v-layout>
      </p>
    </v-flex>
  </v-layout>
</template>
<script>
import HintCard from './HintCard'
import PlanPartsList from './PlanPartsList'
import get from 'lodash/get'

const props = {
  active: {
    type: Boolean,
    default: false
  },
  draggableButtons: {
    type: Array,
    default: () => [
      ['Rf', 'One'],
      ['Radar', 'Zero'],
      ['Drone', 'Opt'],
      ['DroneSentry-X', 'Detect-Only'],
      ['DroneSentry-', 'X'],
      ['Rf', 'Patrol']
    ]
  },
  sensorIds: {
    type: Array,
    default: () => [
      'rf_two',
      'radar_zero',
      'droneopt1',
      'dsx_direct_no_jam',
      'dsx_direct',
      'rf_patrol'
    ] //this needs to be the same order as draggableButtons
  },
  cardsData: {
    type: Object,
    default: () => ({
      RfOne: {
        title: 'RfOne',
        content: `Rf<b>One</b> the ideal RF directional detection solution for large sites that require long range and highly accurate detection and tracking capabilities.
          <ul class="mt-2">
            <li>Uses regularly updated DroneShield proprietary detection database</li>
            <li>Can be deployed across different installations to enable triangulation</li>
          </ul>`,
        product_url: 'https://www.droneshield.com/rfonemkii',
        range: 8,
        coverage: 90
      },
      RadarZero: {
        title: 'RadarZero',
        content: `Radar<b>Zero</b> allows for precise 3D tracking of airborne targets with customizable field-of-view and real beam scanning.
        <ul class="mt-2">
          <li>Independently certified as safe to deploy in an airport environment</li>
          <li>Configurable through the DroneSentry-C2 user interface</li>
        </ul>`,
        range: 1,
        coverage: 90
      },
      DroneOpt: {
        title: 'DroneOpt',
        content: `Drone<b>Opt</b> is an exceptionally rugged, high definition PTZ camera for Electro-Optical verification, integrated with the AI-powered <a href="https://www.droneshield.com/droneoptid" target="_blank">Drone<b>OptID</b></a>:
          <ul class="mt-2">
            <li>Detect, identify and track aerial targets in real-time</li>
            <li>Automatic recording for evidence collection</li>
          </ul>`,
        product_url: 'https://www.droneshield.com/droneopt',
        range: 1,
        coverage: 360
      },
      'DroneSentry-XDetect-Only': {
        title: 'DroneSentry-X (Detect)',
        content: `DroneSentry-X is a cross-vehicle compatible, automated 360° detect and defeat device. It provides 360° awareness and protection using integrated sensors to detect and disrupt UAS moving at any speed - suitable for mobile operations, on site surveillance and on the move missions. `,
        product_url: 'https://www.droneshield.com/sentry-x',
        range: 2,
        coverage: 360
      },
      'DroneSentry-X': {
        title: 'DroneSentry-X (Detect & Defeat)',
        content: `DroneSentry-X is a cross-vehicle compatible, automated 360° detect and defeat device. It provides 360° awareness and protection using integrated sensors to detect and disrupt UAS moving at any speed - suitable for mobile operations, on site surveillance and on the move missions. `,
        product_url: 'https://www.droneshield.com/sentry-x',
        range: 2,
        coverage: 360
      },
      RfPatrol: {
        title: 'RfPatrol',
        content: `Rf<b>Patrol</b> is a highly versatile, completely passive wearable UAS detection device.
        <ul class="mt-2">
          <li>The device offers the user real situational awareness without distraction or complex operation.</li>
          <li>It has been designed to be highly effective for a variety of operators in a range of demanding environments.</li>
          <li>The RfPatrol device automatically detects drones moving at any speed.</li>
        </ul>`,
        product_url: 'https://www.droneshield.com/rfpatrol',
        range: 1,
        coverage: 360
      },
      information: {
        title: 'Instructions',
        content: `<ul>
            <li>Click on any sensor above to display information, drag and drop on the map to create an installation or add to an existing one</li>
            <li>Select and drag sensors and installations to adjust their position</li>
            <li>Click on a directional sensor coverage to change its 90° sector</li>
          </ul>`,
        do_not_remove: true
      },
      airport: {
        title: 'Airport',
        advice: true,
        content: `<ul>
          <li>1-2 Rf<b>One</b> sensors at the end of each runway are recommended as a minimum solution.</li>
        </ul>`
      },
      prison: {
        title: 'Prison',
        advice: true,
        content: `<ul>
          <li>Two Rf<b>One</b> at opposite corners of the facility can provide tracking of the drone across the prison.</li>
        </ul>`
      },
      border: {
        title: 'Country Border',
        advice: true,
        content: `<ul>
          <li>Rf<b>One</b> sensors facing towards each other are recommended, in clusters 10-15km away.</li>
        </ul>`
      }
    })
  },
  themedCards: {
    type: Object,
    default: () => ({
      airport: ['airport'],
      base: [],
      prison: ['prison'],
      border: ['border'],
      event: [],
      vip: [],
      other: []
    })
  },
  plan: {
    type: Object,
    default: () => {}
  }
}

export default {
  name: 'PlanStep3',
  components: { HintCard, PlanPartsList },
  props,
  data: () => ({
    draggingId: null,
    activeHintCards: [],
    droppedSomething: false,
    activeTab: 0
  }),
  methods: {
    cancelStep() {
      this.droppedSomething = false
      this.draggingId = null
      this.activeHintCards = []
      this.activeTab = 0
    },
    dragStart(e, i) {
      this.addCard(Object.keys(this.cardsData)[i])
      this.$store.dispatch('plans/START_DRAGGING')
      event.dataTransfer.setDragImage(document.getElementById('pixel'), 0, 0)
      this.draggingId = i
    },
    dragEnd(e, i) {
      this.$store.dispatch('plans/STOP_DRAGGING')
      e.target.blur()

      const sensorName =
        this.draggableButtons[this.draggingId][0] +
        this.draggableButtons[this.draggingId][1]

      if (document.querySelector('.wizardMap:hover')) {
        this.$bus.$emit('dragEnd', {
          name: 'wizardMap',
          event: e,
          sensorId: this.sensorIds[this.draggingId],
          sensorName: sensorName || this.sensorIds[this.draggingId]
        })
        this.droppedSomething = true
      }
      this.draggingId = null
    },
    addCard(cardKey) {
      const others = this.activeHintCards.filter(k => k !== cardKey)
      this.activeHintCards = [cardKey, ...others]
    },
    deleteCard(cardKey) {
      this.activeHintCards = this.activeHintCards.filter(k => k !== cardKey)
    }
  },
  watch: {
    active(v) {
      if (v) {
        this.$emit('next')
        this.themedCards[this.plan.site_plan.facility_type].forEach(k =>
          this.addCard(k)
        )
        this.addCard('information')
        this.$refs.tabs.onResize()
      }
    }
  }
}
</script>
<style scoped>
.btn-sensor {
  font-size: 1.2em;
  height: 70px;
  text-transform: none;
  cursor: move;
  font-weight: 300;
}
.btn-sensor .bold {
  font-weight: bold;
}
.btn-container:nth-child(4) >>> .v-btn__content {
  flex-direction: column;
}
.btn-container:nth-child(4) >>> .v-btn__content span:first-of-type::after {
  content: 'X';
  font-weight: bold;
  transform: translateX(-1ch);
  position: absolute;
}
</style>
