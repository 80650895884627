// @ts-nocheck
import { parse } from 'papaparse'

export interface IPartsListItem {
  key?: string
  id?: string
  name?: string
  info?: string
  part_no: string
  addon?: boolean
  price: number
  type?: string
  subtype?: string
  need?: string[]
  is_subscription?: boolean
}

const parts: { [key: string]: IPartsListItem } = {
  //<!---------------------------------------------------------------------------Systems---------------------------------------------------------------------------!>
  cameras: {
    name: 'DroneOpt',
    part_no: '107',
    type: 'cameras',
    need: ['droneoptid', 'cameras_subscription'],
    price: 13750
  },

  //<!---------------------Radar--------------------->

  radars: {
    name: 'RadarZero',
    part_no: '103',
    type: 'radars',
    need: ['radars_brackets'],
    price: 55000
  },
  //<!---------------------RF------------------------->

  rf_two: {
    name: 'RfOne',
    part_no: '102',
    type: 'rf_sensors',
    subtype: 'rf_two',
    need: ['rf_two_subscription'],
    price: 45000
  },

  rf_zero: {
    name: 'RfZero',
    part_no: '109',
    type: 'rf_sensors',
    subtype: 'rf_zero',
    need: ['rf_zero_subscription'],
    price: 35000
  },

  rf_patrol: {
    name: 'RfPatrol',
    part_no: '112',
    type: 'rf_sensors',
    subtype: 'rf_patrol',
    info: 'Body-worn RF UAS detector, including 433Mhz, 915Mhz, 2.4Ghz, 5.8Ghz',
    need: ['rfpatrol_mk2_subscription'],
    price: 27000
  },

  //<!---------------------Other--------------------->

  cannons: {
    name: 'DroneCannon',
    need: ['dronecannon_psu'],
    part_no: '105',
    addon: false,
    type: 'cannons',
    price: 62000
  },

  //will remove addon flagonce it becomes an actual sensor
  dsx_direct: {
    name: 'DroneSentry X',
    part_no: '207',
    subtype: 'dsx_direct',
    type: 'dsx_sensors',
    need: ['dronesentry_x_subscription'],
    price: 200000
  },

  dsx_direct_no_jam: {
    name: 'DroneSentry X - Detect only',
    part_no: '208',
    subtype: 'dsx_direct_no_jam',
    type: 'dsx_sensors',
    price: 137500
  },

  discovair_sensors: {
    name: 'Discovair G2',
    part_no: '110',
    type: 'discovair_sensors',
    price: 126250
  },

  smarthub: {
    name: 'SmartHub',
    part_no: '406',
    addon: true,
    price: 20000
  },
  gps: {
    name: 'GPS Module',
    part_no: '406-1',
    addon: true,
    price: 10000
  },

  droneoptid: {
    name: 'DroneOptID Server',
    part_no: '409-1',
    price: 21000
  },

  dailyNRERate: {
    name: 'Daily NRE Rate',
    part_no: '700',
    addon: true,
    info: 'Daily engineering rate/person for NRE/software engineering work',
    price: 1500
  },
  // pelican_3ru: {
  //   name: '3RU Pelican Case',
  //   part_no: '406-2',
  //   info: '3RU Pelican Case (2RU SmartHub + 1RU ComputeNode)'
  // },
  // pelican_4ru: {
  //   name: '4RU Pelican Case',
  //   part_no: '406-3',
  //   info: '4RU Pelican Case (2RU SmartHub + 2RU DroneOptID server)'
  // },
  // pelican_5ru: {
  //   name: '5RU Pelican Case',
  //   part_no: '406-4',
  //   info: '2RU SmartHub + 2RU DroneOptID server + 1RU ComputeNode'
  // },

  droneoptid_case: {
    name: 'DroneOptID rugged case for server',
    part_no: '409-2',
    addon: true,
    price: 3125
  },

  //<!---------------------------------------------------------------ComputeNode Options----------------------------------------------------------------------->
  computenode_prem_10: {
    name: 'ComputeNode (on-premises edition, 1-10 sensors)',
    part_no: '407-1',
    type: 'cn',
    price: 10000
  },
  computenode_prem_20: {
    name: 'ComputeNode (on-premises edition, 11-20 sensors)',
    part_no: '407-2',
    type: 'cn',
    price: 20000
  },
  computenode_prem_50: {
    name: 'ComputeNode (on-premises edition, 21-50 sensors)',
    part_no: '407-3',
    type: 'cn',
    price: 38750
  },
  computenode_cloud_5: {
    name: 'ComputeNode (cloud edition, 1-5 sensors)',
    part_no: '408-1',
    type: 'cn',
    price: 4375
  },
  computenode_cloud_10: {
    name: 'ComputeNode (cloud edition, 6-10 sensors)',
    part_no: '408-2',
    type: 'cn',
    price: 9375
  },
  computenode_cloud_15: {
    name: 'ComputeNode (cloud edition, 11-15 sensors)',
    part_no: '408-3',
    type: 'cn',
    price: 13750
  },
  computenode_cloud_20: {
    name: 'ComputeNode (cloud edition, 16-20 sensors)',
    part_no: '408-4',
    type: 'cn',
    price: 18750
  },
  computenode_cloud_30: {
    name: 'ComputeNode (cloud edition, 21-30 sensors)',
    part_no: '408-5',
    type: 'cn',
    price: 27500
  },
  computenode_cloud_40: {
    name: 'ComputeNode (cloud edition, 31-40 sensors)',
    part_no: '408-6',
    type: 'cn',
    price: 37500
  },
  computenode_cloud_50: {
    name: 'ComputeNode (cloud edition, 41-50 sensors)',
    part_no: '408-7',
    type: 'cn',
    price: 55000
  },

  //<!-------------------------------------------------------------------------------BUNDLES---------------------------------------------------------------------------!>
  dronesentry360: {
    name: 'DroneSentry360',
    part_no: '206',
    need: ['dronesentry360_subscription', 'dronesentry360_brackets'],
    info:
      '4x RfOne, 4x DroneCannon, 1x SmartHub, 1x GPS, 1x ComputeNode, 1x DroneOpt1, 1x DroneOptID Server',
    price: 828800
  },
  dronesentry90_detection: {
    name: 'DroneSentry90 (Detection)',
    part_no: '203',
    need: ['dronesentry90_subscription'],
    info:
      '1x RfOne, 1x SmartHub, 1x GPS, 1x ComputeNode, 1x DroneOpt1, 1x DroneOptID Server',
    price: 205625
  },
  dronesentry90: {
    name: 'DroneSentry90',
    part_no: '204',
    need: ['dronesentry90_subscription'],
    info:
      '1x RfOne, 1 DroneCannon, 1x SmartHub, 1x GPS, 1x ComputeNode, 1x DroneOpt1, 1x DroneOptID Server',
    price: 286875
  },
  dronesentry360_detection: {
    name: 'DroneSentry360 (Detection)',
    part_no: '205',
    need: ['dronesentry360_subscription', 'dronesentry360_detection_brackets'],
    info:
      '4x RfOne, 1x SmartHub, 1x GPS, 1x ComputeNode, 1x DroneOpt1, 1x DroneOptID Server',
    price: 521900
  },

  rf_core: {
    name: 'Rf Core',
    part_no: '201',
    info: '1x RfOne, 1x GPS, 1x ComputeNode, Brackets',
    price: 68125
  },
  rf_tracker: {
    name: 'Rf Tracker',
    part_no: '202',
    info: '2x RfOne (2 installation points), 2x Mast 1, 2x GPS, 1x ComputeNode',
    price: 126250
  },

  //<!---------------------------------------------------------------------------SUBSCRIPTIONS---------------------------------------------------------------------------!>
  rf_zero_subscription: {
    name: 'RfZero Subscription (Yearly)',
    part_no: '109-1',
    addon: true,
    price: 2500,
    is_subscription: true
  },

  rf_two_subscription: {
    name: 'RfOne Subscription (Yearly)',
    part_no: '102-1',
    addon: true,
    price: 5000,
    is_subscription: true
  },

  cameras_subscription: {
    name: 'DroneOptID Subscription (Yearly)',
    part_no: '107-1',
    addon: true,
    price: 5000,
    is_subscription: true
  },

  rfone_subscription: {
    name: 'RfOne Subscription (Yearly)',
    part_no: '102-1',
    addon: true,
    info: 'Subscription for RfOne',
    price: 5000,
    is_subscription: true
  },
  rfpatrol_subscription: {
    name: 'RfPatrol Subscription (Yearly)',
    part_no: '108-1',
    info: 'Subscription for RfPatrol',
    price: 2000,
    addon: true,
    is_subscription: true
  },
  rfpatrol_mk2_subscription: {
    name: 'RfPatrol Subscription (Yearly)',
    part_no: '112-1',
    info: 'Subscription for RfPatrol',
    price: 2500,
    addon: true,
    is_subscription: true
  },
  rfzero_subscription: {
    name: 'RfZero Subscription (Yearly)',
    part_no: '109-1',
    info: 'Subscription for RfPatrol',
    addon: true,
    price: 2500,
    is_subscription: true
  },
  dronesentry90_subscription: {
    name: 'DroneSentry 90 Subscription (Yearly)',
    part_no: '204-1',
    addon: true,
    info: 'Subscription for DroneSentry 90',
    price: 10625,
    is_subscription: true
  },
  dronesentry360_subscription: {
    name: 'DroneSentry 360 Subscription (Yearly)',
    part_no: '206-1',
    addon: true,
    info:
      'Subscription for DroneSentry 360: Includes 4x RfOne and 1x DroneOptID subscription',
    price: 31250,
    is_subscription: true
  },
  dronesentry_x_subscription: {
    name: 'DroneSentry-X Subscription (Yearly)',
    part_no: '207-1',
    addon: true,
    info:
      'Subscription for DroneSentry X: Optional subscription to receive DroneSentry-X protocol updates (per unit, per annum)',
    price: 15000,
    is_subscription: true
  },
  drone_opt_id_subscription: {
    name: 'DroneOptID Subscription (Yearly)',
    part_no: '107-1',
    addon: true,
    info:
      'Subscription for DroneOptID: AI-based engine to detect/ID and track the drones (also requires separate purchase of ComputeNode)',
    price: 6250,
    is_subscription: true
  },

  //<!---------------------------------------------------------------------------ADDONS---------------------------------------------------------------------------!>

  dronegun_tactical: {
    name: 'Dronegun Tactical',
    part_no: '300',
    addon: true,
    info:
      '2km nominal range. Includes carry case, 2 batteries and a charger, manual (optic optional)',
    price: 50000
  },
  dronegun_mk3: {
    name: 'DronegunI',
    part_no: '500',
    addon: true,
    info:
      '1km nominal range. Includes carry case, 2 batteries and a charger, instruction manual',
    price: 33000
  },
  dronesentry360_detection_brackets: {
    name: 'DroneSentry360 Mounting Kit (Detection)',
    part_no: '404-5',
    addon: true,
    price: 12500
  },
  radars_brackets: {
    name: 'RadarZero Mounting Kit',
    part_no: '403-3',
    addon: true,
    price: 1250
  },
  drone_node: {
    name: 'DroneNode',
    part_no: '106',
    addon: true,
    price: 50000
  },
  drone_sim: {
    name: 'DroneSim',
    part_no: '113',
    addon: true,
    price: 18750
  },
  third_party_sensor: {
    name: 'Third Party Sensor',
    part_no: '600-1',
    addon: true,
    price: 6250
  },
  dronecannon_psu: {
    name: 'DroneCannon PSU',
    part_no: '333-101',
    addon: true,
    price: 12500
  },

  dronesentry360_brackets: {
    name: 'DroneSentry360 Mounting Kit',
    part_no: '404-6',
    addon: true,
    price: 27500
  },
  universal_mounting_system: {
    name: 'Universal Mounting  System (UMS)',
    info: 'For pole diameters from 50-89mm. Use for RfOne and DroneCannon',
    part_no: '051',
    addon: true,
    price: 3750
  },

  // rf_two_brackets: {
  //   name: 'RfOne Mounting Kit',
  //   part_no: '403-2'
  // },

  radar_zero_breakout_1_way: {
    name: 'RadarZero breakout box - 1-way',
    part_no: '404-6',
    price: 3750
  },

  radar_zero_breakout_4_way: {
    name: 'RadarZero breakout box - 4-way',
    part_no: '404-7',
    price: 8750
  },

  mast1: {
    name: 'Mast 1',
    part_no: '401',
    addon: true,
    info: '32kg payload deployable to 5m',
    price: 13750
  },
  mast2: {
    name: 'Mast 2',
    part_no: '402',
    addon: true,
    info: '66kg payload deployable to 8m',
    price: 26250
  }
}
//<------------------------------------------ Calculate Shipping logic ------------------------------------------>

// exclude items from shipping cost
const exclude = ['gps']

// default cost of shipping
export const DEFAULT_SHIPPING_COST = 1000

// calculate shipping
Object.keys(parts).map(key => {
  // no shipping
  const noShippingCost = ''

  // if the item is a subscription OR excluded, apply no shipping costs
  if (parts[key].is_subscription || exclude.indexOf(key) !== -1) {
    parts[key].shipping = noShippingCost
  }
  // apply shipping by default
  else {
    parts[key].shipping = DEFAULT_SHIPPING_COST
  }
  return parts
})

export default parts
