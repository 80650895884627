<template>
  <v-flex id="wizardStep1Form">
    <p class="title">Name your new plan</p>
    <v-text-field
      v-model="planName"
      ref="nameField"
      label="Plan Name"
      class="fadein"
      box
      autofocus
      clearable
      append-icon="arrow_forward_ios"
      @focus="$event.target.select()"
      @keyup.enter="confirmName"
      @click:append="confirmName"
      placeholder="Organisation, City, Address..."
    ></v-text-field>
    <template v-if="nameConfirmed">
      <p class="title mt-5">What's the plan location?</p>
      <v-text-field
        v-model="planLocation"
        ref="locationField"
        label="Plan Location"
        class="fadein"
        box
        autofocus
        clearable
        append-icon="arrow_forward_ios"
        :loading="status === 'LOADING'"
        @focus="$event.target.select()"
        @keyup.enter="searchLocation"
        @click:append="searchLocation"
        placeholder="City, Address, Coordinates..."
      ></v-text-field>
      <p
        v-if="searchResult || status === 'FAILED'"
        :class="`fadein ml-3 ${status === 'FAILED' ? 'red--text' : ''}`"
      >
        <v-icon v-if="status === 'OK'" icon small>arrow_forward</v-icon>
        {{
          status === 'FAILED'
            ? 'The entered location was not found, please try again'
            : searchResult
        }}
      </p>
      <template v-if="status === 'OK'">
        <p
          class="title fadein mt-5"
          :style="`animation-delay: ${nextStepReady ? 0 : 4}s`"
        >
          Adjust the plan location
        </p>
        <p
          class="fadein"
          :style="`animation-delay:  ${nextStepReady ? 0 : 4.5}s`"
        >
          Please adjust the location (zoom/drag) to fit the protected area, then
          click "Continue".
        </p>
      </template>
    </template>
  </v-flex>
</template>
<script>
import { mapState } from 'vuex'

const props = {
  active: {
    type: Boolean,
    default: false
  }
}

export default {
  name: 'PlanStep1',
  props,
  data: () => ({
    planLocation: '',
    planName: '',
    nameConfirmed: false,
    nextStepReady: false,
    nextStepReadyTimeout: null
  }),
  mounted() {
    this.$nextTick(() => {
      this.$refs.nameField.focus()
    })
  },
  computed: {
    ...mapState('maps', ['searchResult', 'status'])
  },
  methods: {
    searchLocation() {
      this.$emitter.emit('searchMap', {
        name: 'wizardMap',
        address: this.planLocation
      })
      this.$refs.locationField.$el.querySelector('input').select()
      this.nextStepReadyTimeout = setTimeout(() => {
        this.nextStepReady = true
        this.$emit('next')
      }, 6000)
    },
    confirmName() {
      this.nameConfirmed = true
      if (this.planLocation === '') this.planLocation = this.planName
      if (this.$refs.locationField) this.$refs.locationField.focus()
    },
    cancelStep() {
      this.planLocation = ''
      this.planName = ''
      this.nameConfirmed = false
      this.nextStepReady = false
      if (this.nextStepReadyTimeout) {
        clearTimeout(this.nextStepReadyTimeout)
        this.nextStepReadyTimeout = null
      }
    }
  },
  watch: {
    active(v) {
      if (v) {
        this.$refs.nameField.focus()
        if (this.nextStepReady) this.$emit('next')
      } else if (this.nextStepReady) {
        this.$emit('setPlanName', this.planName)
      }
    },
    planName(v) {
      if (!v) this.$emit('stop')
      else if (this.nextStepReady) this.$emit('next')
    }
  }
}
</script>
