var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('base-map',{ref:("baseMap-" + (this.name)),class:{ exporting: _vm.exporting && _vm.name === 'wizardMapPDF', planMap: true },attrs:{"center":_vm.center,"zoom":_vm.zoom,"editable":"","name":_vm.name,"searchBar":_vm.searchBar,"measureEnabled":_vm.measureEnabled,"disabled":_vm.disabled,"layerSwitch":!_vm.exporting,"zoomControl":!_vm.exporting,"suppressModeWarning":true},on:{"update:center":function($event){return _vm.$emit('update:center', $event)},"update:zoom":function($event){return _vm.$emit('update:zoom', $event)},"click":function($event){_vm.$emit('click', $event)
    _vm.selectSentry(null)
    _vm.onMapClick()}}},[(_vm.activeInstallation && _vm.name !== 'wizardMapPDF')?_c('sentry-marker',{key:("active-sentry-marker-" + (_vm.activeInstallation.id)),attrs:{"coords":_vm.activeInstallation.coordinates,"draggable":true,"icon-size":_vm.iconSize,"sentry":_vm.activeInstallation,"active-sentry-id":_vm.activeInstallation.id,"shadow":true},on:{"moveend":_vm.onMoveInstallation}}):_vm._e(),(_vm.loadedPlan)?_c('intersection-coverage',{attrs:{"visible":!_vm.activeInstallation,"sensors":_vm.rf_sensorsSet}}):_vm._e(),(_vm.siteCenter)?_c('site-marker',{attrs:{"center":_vm.siteCenter}}):_vm._e(),_c('LFeatureGroup',{key:_vm.planIdentifier,ref:"coverage-features",on:{"ready":_vm.featuresReady}},[_vm._l((_vm.installations),function(item){return [(!_vm.activeInstallation || item.id !== _vm.activeInstallation.id)?_c('sentry-marker',{key:("sentry-marker-" + (item.id)),attrs:{"active-sentry-id":_vm.activeSentryId,"coords":item.coordinates,"icon-size":_vm.iconSize,"sentry":item,"shadow":true,"draggable":_vm.draggableSensors && item.id === _vm.activeSentryId},on:{"selectSentry":function($event){_vm.name !== 'wizardMapPDF' && _vm.draggableSensors && _vm.selectSentry($event)},"update:latLng":function($event){return _vm.$emit('update:latLng', $event)}}}):_vm._e(),(item.sensors)?[(item.sensors.rf_sensors)?_vm._l((item.sensors.rf_sensors),function(sensor){return _c('rf-sector-coverage',{key:("rf-" + (item.id) + "-" + (sensor.id) + "-" + _vm.draggableSensors),attrs:{"visible":true,"draggable":_vm.draggableSensors,"cancel-drag-handler":_vm.cancelDragHandler,"sensor":Object.assign({}, sensor,
            {longitude: item.coordinates[1],
            latitude: item.coordinates[0]})}})}):_vm._e(),(item.sensors.dsx_sensors)?_vm._l((item.sensors.dsx_sensors),function(sensor){return _c('dronesentryx-sector-coverage',{key:("dsx-" + (item.id) + "-" + (sensor.id) + "-" + _vm.draggableSensors),attrs:{"visible":true,"draggable":_vm.draggableSensors,"cancel-drag-handler":_vm.cancelDragHandler,"sensor":Object.assign({}, sensor,
            {longitude: item.coordinates[1],
            latitude: item.coordinates[0]})}})}):_vm._e(),(item.sensors.discovair_sensors)?_vm._l((item.sensors.discovair_sensors),function(sensor){return _c('discovair-sector-coverage',{key:("discovair-" + (item.id) + "-" + (sensor.id) + "-" + _vm.draggableSensors),attrs:{"visible":true,"draggable":_vm.draggableSensors,"cancel-drag-handler":_vm.cancelDragHandler,"sensor":sensor}})}):_vm._e(),(item.sensors.radars)?_vm._l((item.sensors.radars),function(sensor){return _c('radar-sector-coverage',{key:("radars-" + (item.id) + "-" + (sensor.id) + "-" + _vm.draggableSensors),attrs:{"visible":true,"draggable":_vm.draggableSensors,"cancel-drag-handler":_vm.cancelDragHandler,"sensor":Object.assign({}, sensor,
            {longitude: item.coordinates[1],
            latitude: item.coordinates[0]})}})}):_vm._e(),(item.sensors.cannons)?_vm._l((item.sensors.cannons),function(sensor){return _c('cannon-sector-coverage',{key:("cannons-" + (item.id) + "-" + (sensor.id) + "-" + _vm.draggableSensors),attrs:{"visible":true,"draggable":_vm.draggableSensors,"cancel-drag-handler":_vm.cancelDragHandler,"sensor":Object.assign({}, sensor,
            {longitude: item.coordinates[1],
            latitude: item.coordinates[0]})}})}):_vm._e(),(item.sensors.cameras)?_vm._l((item.sensors.cameras),function(sensor){return _c('camera-sector-coverage',{key:("cameras-" + (item.id) + "-" + (sensor.id) + "-" + _vm.draggableSensors),attrs:{"visible":true,"draggable":_vm.draggableSensors,"sensor":Object.assign({}, sensor,
            {longitude: item.coordinates[1],
            latitude: item.coordinates[0],
            forcedReach: 1000})}})}):_vm._e()]:_vm._e()]})],2),[_vm._l((_vm.allZones),function(z,i){return _c('l-polygon-tooltip',{key:("z-" + i + "-" + (_vm.activeZone.id)),attrs:{"visible":z.id !== _vm.activeZone.id || !_vm.zoneEditMode,"lat-lngs":z.coordinate_list,"color":"yellow","weight":3,"fill-color":"yellow","fill-opacity":z.id === _vm.activeZone.id ? 0.2 : 0.1,"dash-array":"10, 15","line-cap":"square","opacity":0.5,"tooltip":z.name,"hoverProperties":{ opacity: 0.7 }},on:{"click":function($event){return _vm.$bus.$emit('activateZone', z.id)}}})}),(_vm.zoneEditMode)?_c('editable-polygon',{attrs:{"editable":_vm.zoneEditMode,"lat-lngs":_vm.activeZone.coordinate_list,"color":"yellow","fill-opacity":0.1,"weight":3,"fill-color":"yellow"},on:{"editable:vertex:dragend":_vm.onShapeChange}}):_vm._e()],(!_vm.draggableSensors)?[(_vm.viewShedAreas.length > 0)?[_vm._l((_vm.viewShedAreas),function(z,i){return _c('polygon-fog-of-war',{key:("vs-" + i),attrs:{"lat-lngs":z,"opacity":0,"fill-opacity":0,"fill-color":"black","mask":"fogOfWar"}})}),_c('polygon-fog-of-war',{attrs:{"fill-opacity":0,"mask-opacity":0.7,"fill-color":"black","opacity":0,"mask-id":"fogOfWar"}})]:_vm._e(),_vm._l((_vm.rfPerfIsobands),function(z,i){return _c('l-polygon-tooltip',{key:("rfperf-" + i),attrs:{"visible":_vm.rfPerfIsobands.length > 0,"lat-lngs":z.geometry.coordinates,"tooltip":i === _vm.rfPerfIsobands.length - 1
          ? 'Intersection'
          : ((_vm.accuracyLabels[i]) + " Accuracy"),"hoverProperties":{ opacity: 0.3 },"fill-opacity":0.7 - (i + 1) / (2 * _vm.rfPerfIsobands.length),"fill-color":"#d1deec","color":"white","weight":2,"opacity":0}})})]:_vm._e()],2)}
var staticRenderFns = []

export { render, staticRenderFns }