<template>
  <v-layout row wrap>
    <v-flex xs12>
      <p class="title">What's the facility type?</p>
      <p class="fadein">
        Personalized advice will be given based on facility type.
      </p>
    </v-flex>
    <v-flex xs12 class="pt-3 px-4">
      <v-btn
        v-for="(facility, i) in facilityType"
        :key="`btn-type-${i}`"
        outline
        block
        color="primary"
        :class="
          `mb-3 ${i === facilityIndex ? 'highlightFacility' : ''} fadein ${
            facilityIndex == -1 ? 'delay-05' : ''
          }`
        "
        @click="facilityIndex = i"
        ><v-icon class="mr-2">{{ facilityIcons[i] }}</v-icon>
        {{ facility }}</v-btn
      >
    </v-flex>
  </v-layout>
</template>
<script>
const props = {
  active: {
    type: Boolean,
    default: false
  }
}

export default {
  name: 'PlanStep2',
  props,
  data: () => ({
    facilityType: [
      'Airport',
      'Base/Compound',
      'Prison',
      'Country Border',
      'Event',
      'VIP Facility',
      'Other'
    ],
    facilityIcons: [
      'flight_takeoff',
      'security',
      'lock',
      'flag',
      'event',
      'star',
      'apartment'
    ],
    facilityIds: [
      'airport',
      'base',
      'prison',
      'border',
      'event',
      'vip',
      'other'
    ],
    facilityIndex: -1
  }),
  methods: {
    cancelStep() {
      this.facilityIndex = -1
    }
  },
  watch: {
    facilityIndex() {
      this.$emit('next')
      this.$emit('setPlanType', this.facilityIds[this.facilityIndex])
    },
    active(v) {
      if (v && this.facilityIndex != -1) this.$emit('next')
    }
  }
}
</script>
<style scoped>
.v-btn {
  height: 60px;
  font-size: 1.5em;
}
.v-btn.highlightFacility {
  box-shadow: 0px 0px 30px rgba(255, 145, 0, 0.3);
  background-color: rgba(255, 145, 0, 0.2) !important;
}
</style>
