<template>
  <v-card>
    <v-card-title class="pa-0">
      <v-subheader>
        Installations
        <v-tooltip right>
          <v-btn
            flat
            icon
            color="primary"
            @click="initInstallation"
            slot="activator"
          >
            <v-icon>add</v-icon>
          </v-btn>
          <span>Create Installation</span>
        </v-tooltip>
      </v-subheader>
    </v-card-title>
    <template v-if="activeInstallation && editMode">
      <v-card-title>
        <v-subheader class="pa-0">
          {{
            activeInstallation.id >= 0
              ? 'Edit Installation'
              : 'New Installation'
          }}
          <v-btn flat icon @click="onCancelInstallationChange">
            <v-icon color="primary">close</v-icon>
          </v-btn>
        </v-subheader>
        <v-form>
          <v-text-field label="Name" v-model="activeInstallation.name" />
          <v-container grid-list-md grey--text class="pt-0 pl-0">
            <v-subheader class="pa-0">Coordinates</v-subheader>
            <v-layout row wrap>
              <v-flex xs12 class="py-0">
                <v-text-field
                  label="Latitude"
                  v-model="activeInstallation.coordinates[0]"
                  placeholder="Enter latitude"
                ></v-text-field>
              </v-flex>
              <v-flex xs12 class="pt-0">
                <v-text-field
                  label="Longitude"
                  v-model="activeInstallation.coordinates[1]"
                  placeholder="Enter latitude"
                ></v-text-field>
              </v-flex>
            </v-layout>
            <template v-if="activeInstallation.id >= 0">
              <v-subheader class="pa-0">Popup offset</v-subheader>
              <v-layout row wrap class="ml-2">
                <v-flex xs6 class="py-0">
                  <v-slider
                    v-model="activeInstallation.offset[0]"
                    @input="$bus.$emit('updatePopup', activeInstallation.id)"
                    :min="-150"
                    :max="150"
                    label="x"
                    thumb-label
                  ></v-slider>
                </v-flex>
                <v-flex xs6 class="py-0">
                  <v-slider
                    v-model="activeInstallation.offset[1]"
                    @input="$bus.$emit('updatePopup', activeInstallation.id)"
                    :min="-150"
                    :max="150"
                    label="y"
                    thumb-label
                  ></v-slider>
                </v-flex>
              </v-layout>
            </template>
          </v-container>
          <v-btn block color="primary" outline @click="saveInstallation">
            {{ activeInstallation.id >= 0 ? 'Update' : 'Save' }}
            <v-icon right dark>save</v-icon>
          </v-btn>
        </v-form>
      </v-card-title>
    </template>
    <v-card-text class="pa-0">
      <v-expansion-panel
        class="elevation-0"
        @input="onSelect"
        :value="activatedSentry"
      >
        <v-expansion-panel-content
          v-for="(item, i) in installations"
          :key="`s${i}`"
        >
          <v-layout slot="header" align-center row spacer>
            <v-flex xs2>
              <v-icon color="green">radio_button_checked</v-icon>
            </v-flex>
            <v-flex xs6>
              <span class="body-1">{{ item.name }}</span>
            </v-flex>
            <v-tooltip bottom>
              <v-btn
                flat
                icon
                class="mr-0"
                @click.stop="onEditInstallation(item)"
                slot="activator"
                :disabled="editMode"
              >
                <v-icon color="white">edit</v-icon>
              </v-btn>
              <span>Edit Installation</span>
            </v-tooltip>
            <v-tooltip bottom>
              <v-btn
                flat
                icon
                class="ml-0"
                @click.stop="
                  dialog = item
                  installationToDelete = item
                "
                slot="activator"
                :disabled="editMode"
              >
                <v-icon color="white">delete</v-icon>
              </v-btn>
              <span>Delete Installation</span>
            </v-tooltip>
          </v-layout>
          <sentry-card :sentry="item" :edit-mode="true" />
        </v-expansion-panel-content>
        <v-dialog v-model="dialog" max-width="300px">
          <v-card>
            <v-card-text>
              {{
                `Delete installation ${installationToDelete &&
                  installationToDelete.name}?`
              }}
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn flat color="primary" @click="dialog = false">Cancel</v-btn>
              <v-btn flat color="primary" @click="onDeleteInstallation"
                >Confirm</v-btn
              >
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-expansion-panel>
    </v-card-text>
    <v-card-text class="px-3 pt-1 pb-1">
      <v-btn
        block
        @click="partsListDialog = true"
        :disabled="partsListDialog || !installations.length"
        outline
        v-if="user.show_plan_part_lists"
      >
        Component List
        <v-icon class="ml-3" style="transform: scale(-1)">menu_open</v-icon>
      </v-btn>

      <v-dialog
        max-width="550px"
        hide-overlay
        :value="partsListDialog"
        persistent
        transition="slide-x-transition"
        lazy
        content-class="partsListDialog"
      >
        <v-card height="100%">
          <v-card-title class="title pl-4">
            <v-layout align-center>
              <v-flex>
                <span class="font-weight-medium">Parts List</span>
              </v-flex>
              <v-flex shrink>
                <v-btn
                  v-if="user.show_plan_part_lists"
                  small
                  flat
                  color="primary"
                  @click="pdfDialog = true"
                  >PDF
                  <v-icon class="pl-2" small>insert_drive_file</v-icon></v-btn
                >
                <v-btn
                  v-if="isDRO"
                  small
                  flat
                  color="primary"
                  @click="$refs.partsList && $refs.partsList.exportCSV()"
                  >CSV
                  <v-icon class="pl-2" small>insert_drive_file</v-icon></v-btn
                >
                <v-tooltip right color="sdark">
                  <v-btn
                    icon
                    flat
                    @click.native="partsListDialog = false"
                    slot="activator"
                  >
                    <v-icon style="transform: scale(-1)">double_arrow</v-icon>
                  </v-btn>
                  <span>Hide Parts List</span>
                </v-tooltip>
              </v-flex>
            </v-layout>
          </v-card-title>
          <v-card-text class="pa-0">
            <v-container grid-list-md>
              <v-layout row wrap align-center>
                <v-flex xs12>
                  <plan-parts-list
                    v-if="partsListDialog"
                    ref="partsList"
                    class="pa-0 parts-list"
                    :plan="activePlan"
                  />
                </v-flex>
              </v-layout>
            </v-container>
          </v-card-text>
        </v-card>
      </v-dialog>
      <!-- TODO: remove key dialog -->
      <v-dialog
        v-model="pdfDialog"
        max-width="100vh"
        :key="`incpdf-${inc}`"
        scrollable
        style="max-height: 100vh"
        content-class="pdfDialog"
      >
        <v-card>
          <v-card-title class="headline sdark">
            <span class="primary--text text-uppercase font-weight-medium">
              PDF Export
            </span>
          </v-card-title>
          <v-card-text>
            <v-layout row wrap>
              <!-- <v-flex xs12 class="ma-2">

              </v-flex> -->
              <v-flex xs4 class="pr-2">
                <v-form>
                  <v-text-field
                    label="Plan Name"
                    v-model="exportData.planName"
                    placeholder="XYZ Location"
                  ></v-text-field>
                </v-form>
              </v-flex>
              <v-flex xs4 class="pl-2">
                <v-form>
                  <v-text-field
                    label="Customer Name"
                    v-model="exportData.customerName"
                    placeholder="ABC Company"
                  ></v-text-field>
                </v-form>
              </v-flex>
              <v-flex xs4 class="pl-2">
                <v-form>
                  <v-text-field
                    label="Shipment Time (days)"
                    type="number"
                    v-model="exportData.shipmentTime"
                    placeholder="e.g 45"
                  ></v-text-field>
                </v-form>
              </v-flex>
              <v-flex xs12 class="mb-2">
                <!-- <v-subheader>Plan Map Overview</v-subheader> -->
                <plan-map
                  style="height: 60vh"
                  :center="activePlan.site_plan.coordinates"
                  :zoom="activePlan.site_plan.zoom - 1"
                  name="wizardMapPDF"
                  :searchBar="false"
                  :plan="activePlan"
                  :draggableSensors="thickSectors"
                  :cancel-drag-handler="true"
                  class="wizardMap"
                  :active="pdfDialog"
                  :measureEnabled="false"
                  :active-zone="activeZone"
                />

                <v-alert
                  class="mt-3"
                  :value="true"
                  type="info"
                  outline
                  color="white"
                  >Adjust map position and zoom level, click on each
                  installation to show/hide the sensors list.</v-alert
                >
              </v-flex>
              <!-- Map -->

              <v-flex xs4 class="pr-2">
                <v-form>
                  <v-text-field
                    label="DroneShield POC Name"
                    v-model="exportData.droName"
                    placeholder="Name Surname"
                  ></v-text-field>
                </v-form>
              </v-flex>
              <v-flex xs4>
                <v-form>
                  <v-text-field
                    label="DroneShield POC Email"
                    v-model="exportData.droEmail"
                    placeholder="email@droneshield.com"
                  ></v-text-field>
                </v-form>
              </v-flex>
              <v-flex xs4 class="pl-2">
                <v-form>
                  <v-text-field
                    label="DroneShield POC Phone"
                    v-model="exportData.droPhone"
                    placeholder="Enter phone number"
                  ></v-text-field>
                </v-form>
              </v-flex>

              <v-switch
                label="Thick sensor sectors"
                v-model="thickSectors"
                type="Boolean"
                color="primary"
                class="my-0"
              />

              <!-- Parts list -->
              <!-- DroneShield logo -->
            </v-layout>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn flat color="primary" @click="pdfDialog = false"
              >Cancel</v-btn
            >
            <plan-pdf-export
              :key="`pdfexport-${inc}`"
              :v-bind="exportData"
              :planName="exportData.planName"
              :customerName="exportData.customerName"
              :droEmail="exportData.droEmail"
              :droPhone="exportData.droPhone"
              :droName="exportData.droName"
              :shipmentTime="parseInt(exportData.shipmentTime)"
              planMapName="wizardMapPDF"
              @done="pdfDialog = false"
            />
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-card-text>
  </v-card>
</template>
<script>
import { mapGetters, mapActions, mapState } from 'vuex'
import SentryCard from '@/components/Sentry/SentryCard'
import { PlanPartsList } from '@/components/Widgets/Plan/Wizard'
import PlanMap from '@/components/Map/PlanMap'
import PlanPdfExport from './PlanPdfExport.vue'

const props = {
  center: {
    type: Array,
    default: () => [0, 0]
  },
  clickedPosition: {
    type: Array,
    default: () => [0, 0]
  },
  thickSectors: {
    type: Boolean,
    default: false
  }
}

export default {
  name: 'InstallationsWidget',
  components: {
    SentryCard,
    PlanPartsList,
    PlanMap,
    PlanPdfExport
  },
  props,
  data: () => ({
    editMode: false,
    dialog: false,
    installationToDelete: null,
    partsListDialog: false,
    pdfDialog: false,
    exportData: {
      planName: null,
      customerName: null,
      shipmentTime: 100, //in days, default 100
      droEmail: null,
      droName: null,
      droPhone: null
    },
    inc: 0,
    activeZone: {
      id: null,
      coordinate_list: [],
      name: ''
    },
    pdfDialogStyle: {
      maxHeight: '100%'
    }
  }),
  computed: {
    ...mapState('sentries', ['activeSentryId']),
    ...mapState('users', ['user']),
    ...mapGetters('users', ['isDRO']),
    ...mapGetters('plans', ['activeInstallation', 'activePlan']),
    ...mapGetters('maps', ['exporting']),
    ...mapState('selection', ['activeSentries']),
    activatedSentry() {
      const installationIds = this.activePlan.site_plan.installations.map(
        installation => installation.id
      )
      return this.activeSentries.find(sentry => {
        return installationIds.includes(sentry)
      })
    },
    installations() {
      return (
        (this.activePlan.site_plan &&
          this.activePlan.site_plan.installations) ||
        []
      )
    },
    sensors() {
      let data = { cannons: [], radars: [], rf_sensors: [], gps_compasses: [] }
      return sensorTypes =>
        Object.keys(data)
          .filter(key => sensorTypes.includes(key))
          .reduce((acc, cur) => Object.assign(acc, { [cur]: data[cur] }), {})
    }
  },
  methods: {
    ...mapActions('plans', {
      activateInstallation: 'ACTIVATE_INSTALLATION',
      createInstallation: 'CREATE_INSTALLATION',
      updateInstallation: 'UPDATE_INSTALLATION',
      deleteInstallation: 'DELETE_INSTALLATION'
    }),
    ...mapActions('sentries', { selectSentry: 'SELECT_SENTRY' }),
    ...mapActions('selection', [
      'activateSentry',
      'deactivateSentry',
      'clearSentries',
      'setActiveSentry'
    ]),
    initInstallation() {
      this.activateInstallation({
        id: -1,
        name: [
          'ALPHA',
          'BRAVO',
          'CHARLIE',
          'DELTA',
          'ECHO',
          'FOXTROT',
          'GOLF',
          'HOTEL',
          'INDIA',
          'JULIETT',
          'KILO',
          'LIMA',
          'New Installation'
        ][
          Math.min(
            (this.installations.slice(-1)[0] || 0) &&
              this.installations.slice(-1)[0].id + 1,
            12
          )
        ],
        coordinates: this.center,
        offset: [102, -13]
      })
      this.editMode = true
    },
    saveInstallation() {
      if (this.activeInstallation.id >= 0)
        this.updateInstallation(this.activeInstallation)
      else this.createInstallation(this.activeInstallation)
      this.onCancelInstallationChange()
    },
    onCancelInstallationChange() {
      this.activateInstallation(null)
      this.editMode = false
    },
    onEditInstallation(item) {
      this.activateInstallation(item)
      this.editMode = true
    },
    onDeleteInstallation() {
      this.deleteInstallation(this.installationToDelete)
      this.onCancelInstallationChange()
      this.installationToDelete = null
      this.dialog = false
    },
    async onSelect(item) {
      if (item !== null) {
        await this.setActiveSentry(this.installations[item].id)
      } else {
        await this.deactivateSentry(this.activeSentryId)
      }
      this.$store.commit('setActiveGroup', {})
      await this.$store.dispatch('sensors/UNSELECT_ALL')
      this.selectSentry(
        item == null ? item : this.activePlan.site_plan.installations[item].id
      )
    }
  },
  mounted() {
    this.onCancelInstallationChange()
  },
  watch: {
    pdfDialog(v) {
      if (v) {
        this.inc++
        Object.keys(this.exportData).forEach(k => (this.exportData[k] = null))
        this.exportData.planName = this.activePlan.name
        this.exportData.droName = this.user.name
        this.exportData.droEmail = this.user.email
        this.exportData.shipmentTime = 100
      }
    },
    thickSectors(v) {
      this.inc++
    },
    exporting(v) {
      if (!v) {
        this.inc++
      }
    }
  }
}
</script>
<style>
.partsListDialog {
  position: absolute !important;
  margin: 0;
  max-height: 100% !important;
  top: 85px;
  left: 390px;
  bottom: 15px;
}
.partsListDialog .v-card__title {
  box-shadow: 0px 0px 13px rgba(0, 0, 0, 0.15);
  border-bottom: solid 2px rgba(0, 0, 0, 0.1);
}
.partsListDialog .v-card__text {
  height: calc(100% - 80px);
  overflow-y: scroll;
}

.pdfDialog {
  max-height: 100vh !important;
}
</style>
