import axios from 'axios'
import union from '@turf/union'
import { polygon } from '@turf/helpers'

const ARCGIS_CLIENT_ID = ''
const ARCGIS_CLIENT_SECRET = ''

const getToken = async () => {
  let arcgisToken = localStorage.getItem('ARCGIS_TOKEN')
  if (arcgisToken) return arcgisToken
  let bodyFormData = new FormData()
  bodyFormData.set('client_id', ARCGIS_CLIENT_ID)
  bodyFormData.set('client_secret', ARCGIS_CLIENT_SECRET)
  bodyFormData.set('grant_type', 'client_credentials')
  bodyFormData.set('expiration', 1440)
  let result = await axios({
    method: 'post',
    url: 'https://www.arcgis.com/sharing/rest/oauth2/token/',
    data: bodyFormData,
    config: { headers: { 'Content-Type': 'multipart/form-data' } }
  })
  localStorage.setItem('ARCGIS_TOKEN', result.data.access_token)
  return result.data.access_token
}

const clearToken = () => {
  localStorage.removeItem('ARCGIS_TOKEN')
}

const startAnalysis = async areas => {
  let token = await getToken()
  let bodyFormData = new FormData()
  bodyFormData.set('token', token)
  bodyFormData.set(
    'InputPoints',
    JSON.stringify({
      geometryType: 'esriGeometryPoint',
      spatialReference: {
        wkid: 4326
      },
      fields: [
        {
          name: 'OFFSETA',
          type: 'esriFieldTypeDouble',
          alias: 'OFFSETA'
        },
        {
          name: 'OFFSETB',
          type: 'esriFieldTypeDouble',
          alias: 'OFFSETB'
        },
        {
          name: 'VERT1',
          type: 'esriFieldTypeDouble',
          alias: 'VERT1'
        },
        {
          name: 'VERT2',
          type: 'esriFieldTypeDouble',
          alias: 'VERT2'
        },
        {
          name: 'AZIMUTH1',
          type: 'esriFieldTypeDouble',
          alias: 'AZIMUTH1'
        },
        {
          name: 'AZIMUTH2',
          type: 'esriFieldTypeDouble',
          alias: 'AZIMUTH2'
        }
      ],
      features: areas
    })
  )
  bodyFormData.set('MaximumDistance', 5000)
  bodyFormData.set('MaximumDistanceUnits', 'Meters')
  bodyFormData.set('DEMResolution', 'FINEST')
  bodyFormData.set('ObserverHeight', null)
  bodyFormData.set('ObserverHeightUnits', 'Meters')
  bodyFormData.set('SurfaceOffset', null)
  bodyFormData.set('SurfaceOffsetUnits', 'Meters')
  bodyFormData.set('GeneralizeViewshedPolygons', true)
  bodyFormData.set('returnZ', false)
  bodyFormData.set('returnM', false)
  bodyFormData.set('f', 'json')
  bodyFormData.set('env:outSR', JSON.stringify({ wkid: 4326 }))
  let result = await axios({
    method: 'post',
    url:
      'https://elevation.arcgis.com/arcgis/rest/services/Tools/Elevation/GPServer/Viewshed/submitJob',
    data: bodyFormData,
    config: { headers: { 'Content-Type': 'multipart/form-data' } }
  })

  if (result.data.error && result.data.error.code == 498) {
    clearToken()
    return await startAnalysis(areas)
  }
  return result.data
}

const checkStatus = async jobId => {
  let token = await getToken()
  let bodyFormData = new FormData()
  bodyFormData.set('token', token)
  bodyFormData.set('f', 'json')
  let status = await axios({
    method: 'post',
    url: `https://elevation.arcgis.com/arcgis/rest/services/Tools/Elevation/GPServer/Viewshed/jobs/${jobId}`,
    data: bodyFormData,
    config: { headers: { 'Content-Type': 'multipart/form-data' } }
  })
  return status.data.jobStatus
}

const getResult = async jobId => {
  let token = await getToken()
  let result = await axios({
    method: 'post',
    url: `https://elevation.arcgis.com/arcgis/rest/services/Tools/Elevation/GPServer/Viewshed/jobs/${jobId}/results/OutputViewshed?token=${token}&f=json`
  })
  let areas = union(
    ...result.data.value.features
      .map(v => v.geometry.rings)
      .flat()
      .map(poly => polygon([poly.map(v => [v[1], v[0]])]))
  )
  return areas.geometry.coordinates
}

export default {
  startAnalysis,
  checkStatus,
  getResult
}
