<template>
  <v-layout row wrap>
    <v-flex xs12 id="wizardStep4Form">
      <p class="title">What disruption?</p>
      <p class="fadein">
        <v-layout row wrap>
          <v-flex
            xs12
            class="px-2"
            v-for="(label, i) in draggableButtons"
            :key="`btn-drag-${i}`"
          >
            <v-btn
              draggable="true"
              block
              outline
              :color="draggingId == i ? 'orange dark' : 'primary'"
              class="btn-sensor"
              @click="addCard(label[0] + label[1])"
              @dragstart="dragStart($event, i)"
              @dragend="dragEnd($event, i)"
              >{{ label[0] }}<span class="bold">{{ label[1] }}</span></v-btn
            > </v-flex
          ><v-flex xs12 class="mt-2">
            <v-tabs
              color="transparent"
              fixed-tabs
              v-model="activeTab"
              dark
              slider-color="primary"
              ref="tabs"
            >
              <v-tab ripple>Information</v-tab>
              <v-tab-item
                :class="`fadein ${droppedSomething ? '' : 'delay-05'} pt-2`"
              >
                <hint-card
                  class="expand"
                  v-for="cardKey in activeHintCards"
                  :key="`hintcard-${cardKey}`"
                  v-bind="cardsData[cardKey]"
                  @delete="deleteCard(cardKey)"
                />
              </v-tab-item>
              <v-tab ripple>Parts List</v-tab>
              <v-tab-item
                :class="`fadein ${droppedSomething ? '' : 'delay-05'} pt-2`"
              >
                <plan-parts-list
                  :plan="plan"
                  @setCNType="$emit('setCNType', $event)"
                />
              </v-tab-item>
            </v-tabs>
          </v-flex>
        </v-layout>
      </p>
    </v-flex>
  </v-layout>
</template>
<script>
import HintCard from './HintCard'
import PlanPartsList from './PlanPartsList'
import get from 'lodash/get'

const props = {
  active: {
    type: Boolean,
    default: false
  },
  draggableButtons: {
    type: Array,
    default: () => [['Drone', 'Cannon']]
  },
  sensorIds: {
    type: Array,
    default: () => ['cannon']
  },
  cardsData: {
    type: Object,
    default: () => ({
      DroneCannon: {
        title: 'DroneCannon',
        content: `Drone<b>Cannon</b> offers automatic non-lethal and non-kinetic countermeasures, forcing drones to either ground at the point of disruptions or return-to-home.
          <ul class="mt-2">
            <li>Effective against drone swarm attacks</li>
            <li>Drone ISM bands jamming as well as GNSS (satellite navigation)</li>
          </ul>`,
        product_url: 'https://www.droneshield.com/dronecannon',
        range: 2,
        coverage: 90
      },
      information: {
        title: 'Instructions',
        content: `<ul>
            <li>Click on any sensor above to display information, drag and drop on the map to create an installation or add to an existing one</li>
            <li>Select and drag sensors and installations to adjust their position</li>
            <li>Click on a directional sensor coverage to change its 90° sector</li>
          </ul>`
      }
    })
  },
  themedCards: {
    type: Object,
    default: () => ({
      airport: [],
      base: [],
      prison: [],
      border: [],
      event: [],
      vip: [],
      other: []
    })
  },
  plan: {
    type: Object,
    default: () => {}
  }
}

export default {
  name: 'PlanStep4',
  components: { HintCard, PlanPartsList },
  props,
  data: () => ({
    draggingId: null,
    activeHintCards: [],
    droppedSomething: false,
    activeTab: 0
  }),
  methods: {
    cancelStep() {
      this.droppedSomething = false
      this.draggingId = null
      this.activeHintCards = []
      this.activeTab = 0
    },
    dragStart(e, i) {
      this.addCard(Object.keys(this.cardsData)[i])
      this.$store.dispatch('plans/START_DRAGGING')
      event.dataTransfer.setDragImage(document.getElementById('pixel'), 0, 0)
      this.draggingId = i
    },
    dragEnd(e, i) {
      this.$store.dispatch('plans/STOP_DRAGGING')
      e.target.blur()
      if (document.querySelector('.wizardMap:hover')) {
        this.$bus.$emit('dragEnd', {
          name: 'wizardMap',
          event: e,
          sensorId: this.sensorIds[this.draggingId]
        })
        this.droppedSomething = true
      }
      this.draggingId = null
    },
    addCard(cardKey) {
      const others = this.activeHintCards.filter(k => k !== cardKey)
      this.activeHintCards = [cardKey, ...others]
    },
    deleteCard(cardKey) {
      this.activeHintCards = this.activeHintCards.filter(k => k !== cardKey)
    }
  },
  watch: {
    active(v) {
      if (v) {
        this.$emit('next')
        this.themedCards[this.plan.site_plan.facility_type].forEach(k =>
          this.addCard(k)
        )
        this.addCard('information')
        this.$refs.tabs.onResize()
      }
    }
  }
}
</script>
<style scoped>
.btn-sensor {
  font-size: 1.2em;
  height: 70px;
  text-transform: none;
  cursor: move;
  font-weight: 300;
}
.btn-sensor .bold {
  font-weight: bold;
}
</style>
