<template>
  <v-sheet elevation="3" class="ma-2 pb-3 fadein">
    <v-layout row wrap>
      <v-flex grow>
        <p class="pt-3 pl-3 title">
          {{ title }}
          <v-chip v-if="advice" class="ma-2"> Recommendation </v-chip>
        </p>
      </v-flex>
      <v-flex shrink v-if="!do_not_remove">
        <v-btn @click="$emit('delete')" icon outline class="no-border"
          ><v-icon>close</v-icon></v-btn
        >
      </v-flex>
      <v-flex
        xs12
        v-if="range && coverage"
        class="pa-2 mb-3"
        style="background-color: rgba(255, 152, 0, 0.05)"
      >
        <v-layout row wrap>
          <v-flex xs6 text-xs-center>
            <v-icon color="primary" small>straighten</v-icon>
            {{ convertValIfImperial(systemSetting, range, 'km') }}{{`${convertUnitIfImperial(systemSetting, 'km')}`}} range</v-flex
          >
          <v-flex xs6 text-xs-center
            ><v-icon color="primary" small>visibility</v-icon>
            {{ coverage }} deg coverage</v-flex
          >
        </v-layout>
      </v-flex>

      <v-flex xs12 class="px-3" v-html="content" />
      <v-flex xs12 class="px-3 mt-1" v-if="product_url">
        <v-btn
          v-if="product_url"
          :href="product_url"
          target="_blank"
          outline
          block
          >See product page</v-btn
        >
      </v-flex>
    </v-layout>
  </v-sheet>
</template>
<script>
import { mapGetters } from 'vuex'
import { convertValIfImperial, convertUnitIfImperial } from '@/store/utils/index'

const props = {
  title: {
    type: String,
    default: null,
  },
  content: {
    type: String,
    default: null,
  },
  product_url: {
    type: String,
    default: null,
  },
  range: {
    type: Number,
    default: null,
  },
  coverage: {
    type: Number,
    default: null,
  },
  do_not_remove: {
    type: Boolean,
    default: false,
  },
  advice: {
    type: Boolean,
    default: false,
  },
}

export default {
  name: 'HintCard',
  props,
  data: () => ({}),
  methods: {
    convertValIfImperial: convertValIfImperial,
    convertUnitIfImperial: convertUnitIfImperial,
  },
  computed: {
    ...mapGetters('system', ['systemSetting']),
  },
  watch: {},
}
</script>
<style>
.v-btn.no-border {
  border: none !important;
}
</style>
