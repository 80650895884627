<template>
  <v-btn
    flat
    color="primary"
    @click="takeMapScreenshot"
    :loading="exportingLocal"
    :disabled="exportingLocal"
    >Export</v-btn
  >
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex'
import jsPDF from 'jspdf'
import 'jspdf-autotable'
import capitalize from 'lodash/capitalize'

import moment from 'moment-timezone'
require('@/assets/fonts/Exo2-Regular')(jsPDF.API)
require('@/assets/fonts/Exo2-SemiBold')(jsPDF.API)

const props = {
  planName: {
    type: String,
    default: ''
  },
  customerName: {
    type: String,
    default: ''
  },
  droEmail: {
    type: String,
    default: ''
  },
  droPhone: {
    type: String,
    default: ''
  },
  droName: {
    type: String,
    default: ''
  },
  planMapName: {
    type: String,
    default: ''
  },
  shipmentTime: {
    type: Number,
    default: 0
  }
}

export default {
  name: 'PlanPdfExport',
  props,
  data: () => ({
    exportingLocal: false
  }),
  computed: {
    ...mapGetters('maps', ['exporting']),
    ...mapState(['mapScreenshot']),
    ...mapState(['pdfPlanPricing'])
  },
  methods: {
    ...mapActions('maps', { setExporting: 'SET_EXPORTING' }),

    takeMapScreenshot() {
      this.exportingLocal = true

      this.$bus.$emit('screenshotMap', this.planMapName)
    },
    exportPDF(mapDataURI) {
      this.exportingLocal = true
      const planName = this.planName
      setTimeout(async () => {
        try {
          const footerLogoUrl = require('@/assets/logo_white.jpg')
          const logoUrl = require('@/assets/logo.png')
          const leftOffset = 10
          //load map Img
          const mapImg = await this.loadImage(mapDataURI)
          //load footer logo
          const footerLogo = await this.loadImage(footerLogoUrl)

          //logo
          const logo = await this.loadImage(logoUrl)
          //init PDF
          let pdfName = this.planName
          let doc = new jsPDF()
          const pageWidth = doc.internal.pageSize.width
          const pageHeight = doc.internal.pageSize.height

          // START PAGE 1
          //add logo on top of every page
          doc.addImage(logo, 'PNG', 7, 10, 15, 15)
          // add pipe next to logo
          doc.line(24, 12, 24, 23)
          doc.setFont('arial')
          doc.setTextColor(255, 133, 39)
          doc.setFontType('bold')

          doc.setFontSize(20)
          doc.setTextColor(0)
          //title of page
          let splitTitle = doc.splitTextToSize(`Site Proposal`, 185)
          doc.text(leftOffset + 18, 20, splitTitle)
          doc.setFontSize(14)
          doc.setFontType('normal')

          //date
          doc.text(pageWidth - 47, 20, moment().format('DD MMMM YYYY'))

          doc.text(leftOffset, 39, `Site: `)

          doc.setFontType('bold')
          doc.text(leftOffset + 11, 39, `${capitalize(this.planName)}`)
          doc.setFontType('normal')
          //customerName
          if (this.customerName) {
            doc.text(leftOffset, 49, `Customer: `)

            doc.setFontType('bold')
            doc.text(leftOffset + 24, 49, `${this.customerName}`)
          }
          //calc img height,
          const scaledWidth = pageWidth - 25
          const scaledHeight = mapImg.height * (scaledWidth / mapImg.width)
          doc.setFontType('normal')
          //add map screenshot
          doc.addImage(
            mapImg,
            'PNG',
            leftOffset,
            59,
            scaledWidth,
            scaledHeight,
            'planMap',
            'NONE'
          )
          // price totals
          //upfront cost
          let proposalInfoStartY = scaledHeight + 59 + 20
          doc.text(leftOffset, proposalInfoStartY, `Total Upfront Cost: `)
          doc.setFontType('bold')
          doc.text(
            leftOffset + 44,
            proposalInfoStartY,
            '$' +
              this.pdfPlanPricing.year1[
                this.pdfPlanPricing.year1.length - 1
              ][4] +
              ' (USD)'
          )
          doc.setFontType('normal')

          //recurring cost
          doc.text(
            leftOffset,
            proposalInfoStartY + 10,
            `Recurring Annual Cost: `
          )
          doc.setFontType('bold')
          doc.text(
            leftOffset + 52,
            proposalInfoStartY + 10,
            '$' +
              this.pdfPlanPricing.year2[
                this.pdfPlanPricing.year2.length - 1
              ][4] +
              ' (USD)'
          )
          doc.setFontType('normal')

          //delivery time
          doc.text(
            leftOffset,
            proposalInfoStartY + 20,
            `Estimated Time to Deliver from Order: `
          )
          doc.setFontType('bold')
          doc.text(
            leftOffset + 86,
            proposalInfoStartY + 20,
            `${this.shipmentTime || 100} days`
          )
          //contact info
          let contactStartY = proposalInfoStartY + 20 + 20
          doc.setFontType('bold')

          doc.text(leftOffset, contactStartY, 'Contact Information')
          doc.setFontType('normal')

          doc.text(leftOffset, contactStartY + 10, `Name: ${this.droName}`)
          doc.text(leftOffset, contactStartY + 17, `Email: ${this.droEmail}`)
          if (this.droPhone) {
            doc.text(leftOffset, contactStartY + 24, `Phone: ${this.droPhone}`)
          }
          // END PAGE 1

          // START PAGE 2
          doc.addPage()
          doc.addImage(logo, 'PNG', 7, 10, 15, 15)
          // add pipe next to logo
          doc.line(24, 12, 24, 23)

          doc.setFontType('bold')

          doc.setFontSize(20)
          doc.setTextColor(0)

          //title of page
          let page2Title = doc.splitTextToSize(`Site Proposal - Cost`, 185)

          doc.text(leftOffset + 18, 20, page2Title)

          doc.setFontSize(14)

          doc.text(leftOffset, 35, 'Upfront Cost - Year 1')
          //upfront cost year 1
          doc.autoTable({
            margin: { left: leftOffset },
            head: [
              [
                'Qty',
                'Part Number',
                'Item',
                'Price Per Unit (USD)',
                'Total Price (USD)'
              ]
            ],
            body: this.pdfPlanPricing.year1,
            startY: 40
          })
          //upfront cost year 2
          doc.text(
            leftOffset,
            doc.lastAutoTable.finalY + 10,
            'Annual Cost - Year 2 Onwards'
          )
          doc.autoTable({
            head: [
              [
                'Qty',
                'Part Number',
                'Item',
                'Price Per Unit (USD)',
                'Total Price (USD)'
              ]
            ],
            body: this.pdfPlanPricing.year2,
            startY: doc.lastAutoTable.finalY + 15,
            margin: { left: leftOffset, bottom: 15 }
          })

          doc.setFontType('normal')

          // END PAGE 2

          /*           //CONTACT INFORMATION
          const remainingPageSpace = pageHeight - doc.lastAutoTable.finalY
          const contactInfoHeight = 80
          let contactTopY = doc.lastAutoTable.finalY + 20
          //if not enough space, create new page and put contact info at the top
          if (remainingPageSpace < contactInfoHeight) {
            doc.addPage()
            contactTopY = 25
          } */

          // FOR EVERY PAGE
          const scaleFactor = 0.06
          let fontSize = doc.internal.getFontSize()

          //for each page, create footer
          const pages = doc.internal.getNumberOfPages()
          for (let j = 1; j < pages + 1; j++) {
            //set font and fontype for page numbers
            doc.setFontSize(9)
            doc.setTextColor(0)
            doc.setFontType('normal')

            //add droneshield logo to each page
            doc.addImage(
              footerLogo,
              'JPEG',
              10,
              283,
              475 * scaleFactor,
              114 * scaleFactor
            )
            //positions for page numbers
            let horizontalPos = pageWidth / 2
            let verticalPos = pageHeight - 10
            doc.setPage(j)
            //add page number
            doc.text(`${j} of ${pages}`, horizontalPos, verticalPos, {
              align: 'center'
            })

            // add confidential
            doc.setTextColor(175)
            doc.setFontSize(12)
            doc.setFontType('bold')
            doc.text(pageWidth - 40, verticalPos, 'CONFIDENTIAL')
          }
          // END FOR EVERY PAGE

          // SAVE PDF
          doc.save(pdfName + '.pdf')
          this.exportingLocal = false
          this.$emit('done')
        } catch (e) {
          this.exportingLocal = false
          console.error(e)
        }
      }, 2000)
    },
    loadImage(url) {
      return new Promise(resolve => {
        let img = new Image()
        img.onload = () => resolve(img)
        img.src = url
      })
    }
  },
  watch: {
    //once mapScreenshot in vuex changes, this function will trigger process of PDF generation
    async mapScreenshot(img) {
      if (img) {
        //reset screenshot to be blank again
        this.$store.commit('setMapScreenshot', '')

        this.exportPDF(img) //call export pdf function
      }
    }
  },
  mounted() {
    this.$bus.$on('partsList', v => {
      this.partsList = v
    })
  },
  beforeDestroy() {
    this.$bus.$off('partsList')
  }
}
</script>
