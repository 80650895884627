<template>
  <v-card>
    <v-card-title class="pa-0">
      <v-subheader class="flex">
        <!-- For importing plan -->
        <input
          type="file"
          ref="file"
          accept=".dro"
          style="display: none"
          @change="this.onImportPlan"
        />

        <div class="pl-2">Plans</div>
        <add-plan-dialog />
        <v-tooltip bottom class="ml-auto">
          <v-btn
            small
            flat
            color="primary"
            @click="$refs.file.click()"
            slot="activator"
            class="pl-1"
          >
            <v-icon small class="mr-1">get_app</v-icon> Import
          </v-btn>
          <span>Import a Plan (.dro file)</span>
        </v-tooltip>
      </v-subheader>
    </v-card-title>
    <v-card-text class="pt-0">
      <v-select
        class="pt-0 mt-3"
        :items="plansList"
        :loading="fetchingPlans"
        :disabled="fetchingPlans"
        item-text="name"
        v-model="activePlan"
        :label="fetchingPlans ? 'Fetching plans...' : 'Select a plan'"
        return-object
        hide-details
        @change="onChangePlan"
        single-line
        no-data-text="None"
      />
    </v-card-text>

    <v-card-title
      class="py-0 px-0"
      v-if="activePlan && activePlan.id !== 'new'"
    >
      <!-- Edit Plan -->
      <v-tooltip bottom v-if="!editMode">
        <v-btn
          slot="activator"
          class="mr-0"
          icon
          flat
          @click="
            editMode = !editMode
            duplicatePlanName = null
          "
          color="white"
        >
          <v-icon>edit</v-icon>
        </v-btn>
        <span>Modify Plan</span>
      </v-tooltip>
      <v-tooltip bottom v-if="editMode">
        <v-btn
          slot="activator"
          class="mr-0"
          icon
          flat
          @click="editMode = !editMode"
          color="primary"
        >
          <v-icon>close</v-icon>
        </v-btn>
        <span>Cancel Changes</span>
      </v-tooltip>

      <!-- Duplicate Plan -->
      <v-tooltip bottom v-if="!duplicatePlanName">
        <v-btn
          slot="activator"
          class="mx-0"
          icon
          flat
          @click="generateDuplicatePlanName"
          color="white"
        >
          <v-icon>file_copy</v-icon>
        </v-btn>
        <span>Duplicate Plan</span>
      </v-tooltip>
      <v-btn
        v-if="duplicatePlanName"
        class="mx-0"
        icon
        flat
        @click="duplicatePlanName = null"
        color="primary"
      >
        <v-icon>close</v-icon>
      </v-btn>

      <!-- Delete Plan -->
      <v-tooltip bottom>
        <v-dialog v-model="dialog" max-width="300px">
          <v-card>
            <v-card-text>{{ `Delete plan ${activePlan.name}?` }}</v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn flat color="primary" @click="dialog = false">Cancel</v-btn>
              <v-btn flat color="primary" @click="onDeletePlan">Confirm</v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
        <v-btn
          slot="activator"
          class="mx-0"
          icon
          flat
          @click="dialog = true"
          color="white"
        >
          <v-icon>delete</v-icon>
        </v-btn>
        <span>Delete Plan</span>
      </v-tooltip>
      <!-- Export/Save Plan -->
      <v-tooltip bottom>
        <v-btn
          slot="activator"
          class="mx-0"
          icon
          flat
          @click="this.onExportPlan"
          color="white"
        >
          <v-icon>save</v-icon>
        </v-btn>
        <span>Export Plan (.dro file)</span>
      </v-tooltip>
    </v-card-title>
    <v-switch
      label="Display Heavy Sectors"
      v-on:change="this.onThickToggle"
      type="Boolean"
      color="primary"
      class="my-0 ml-3 py-2"
    />

    <v-card-title v-if="activePlan" class="pt-0">
      <!-- Edit Plan Form -->
      <v-form v-if="editMode">
        <v-text-field
          ref="planName"
          @focus="$event.target.select()"
          label="Name"
          v-model="activePlan.name"
        />
        <v-container grid-list-md grey--text class="pt-0 pl-0">
          <v-subheader class="pa-0">Coordinates</v-subheader>
          <v-layout row wrap class="ml-2">
            <v-text-field
              label="Latitude"
              v-model.trim="latitude"
              ref="latitude"
            />
            <v-text-field
              label="Longitude"
              v-model.trim="longitude"
              ref="longitude"
            />
            <v-text-field label="Zoom" v-model.trim="zoomcp" ref="zoom" />
          </v-layout>
        </v-container>
        <v-btn block color="primary" outline @click="savePlan">
          Save
          <v-icon right dark>save</v-icon>
        </v-btn>
      </v-form>

      <!-- Duplicate Plan Form -->
      <v-form v-else-if="duplicatePlanName" style="width:100%">
        <v-text-field
          @focus="$event.target.select()"
          label="Duplicate Name"
          v-model="duplicatePlanName"
        />
        <v-btn block color="primary" outline @click="duplicatePlan">
          Duplicate
          <v-icon right dark>save</v-icon>
        </v-btn>
      </v-form>
    </v-card-title>
  </v-card>
</template>
<script>
import { mapActions, mapGetters } from 'vuex'
const AddPlanDialog = () => import('@/components/Dialogs/AddPlan')

const props = {
  center: {
    type: Array,
    default: () => [0, 0]
  },
  zoom: {
    type: Number,
    default: 16
  },
  thickSectors: {
    type: Boolean,
    default: true
  }
}

export default {
  name: 'PlansWidget',
  components: {
    AddPlanDialog
  },
  data: () => ({
    editMode: false,
    duplicatePlanName: null,
    dialog: false,
    snackbarTimeout: null
  }),
  props,
  mounted() {
    this.$on('importPlan', plan => {
      this.activePlan = plan
      this.centerMap(plan)
    })
    this.snackbarTimeout = setTimeout(
      () => this.$bus.$emit('SNACKBAR/PLANNING_TOOL'),
      3000
    )
  },

  beforeDestroy() {
    if (this.snackbarTimeout) {
      clearTimeout(this.snackbarTimeout)
      this.snackbarTimeout = null
    }
  },
  methods: {
    ...mapActions('plans', {
      createPlan: 'CREATE_PLAN',
      updatePlan: 'UPDATE_PLAN',
      deletePlan: 'DELETE_PLAN'
    }),
    initPlan() {
      this.editMode = true
      this.activePlan = {
        name: 'New Plan',
        id: 'new',
        site_plan: {
          coordinates: this.center,
          zoom: this.zoom
        }
      }
      this.$nextTick(() => {
        this.$refs.planName.focus()
      })
    },
    onThickToggle(val) {
      this.$emit('thickToggle', val)
    },
    onImportPlan(val) {
      this.$emit('planImport', val)
    },
    onExportPlan() {
      this.$emit('planExport')
    },
    savePlan() {
      this.activePlan.site_plan = Object.assign({}, this.activePlan.site_plan, {
        coordinates: this.center,
        zoom: this.zoom
      })
      this.activePlan.id === 'new'
        ? this.createPlan(this.activePlan)
        : this.updatePlan(this.activePlan)
      this.onChangePlan(this.activePlan)
    },
    duplicatePlan() {
      const newPlan = Object.assign({}, this.activePlan, {
        name: this.duplicatePlanName
      })
      this.createPlan(newPlan)
      this.onChangePlan(this.activePlan)
    },
    onChangePlan(e) {
      this.editMode = false
      this.duplicatePlanName = null
      this.centerMap(e)
      this.$store.commit('sensors/UPDATE_RF_PERF_ESTIMATION', {data: null})
    },
    onDeletePlan() {
      this.deletePlan(this.activePlan)
      this.activePlan = null
      this.dialog = false
    },
    centerMap(plan) {
      this.$emitter.emit('centerPlanMap', {coordinates: plan.site_plan.coordinates, zoom: plan.site_plan.zoom})
    },
    generateDuplicatePlanName() {
      this.editMode = false
      this.duplicatePlanName = this.activePlan.name.replace(/ \((\d)\)$/, '')
    }
  },
  computed: {
    ...mapGetters('plans', {
      storeActivePlan: 'activePlan',
      plansList: 'plansList',
      status: 'status'
    }),
    fetchingPlans() {
      return this.status == 'LOADING' && this.plansList.length == 0
    },
    activePlan: {
      get: function() {
        return this.storeActivePlan
      },
      set: function(plan) {
        this.$store.dispatch('plans/ACTIVATE_PLAN', plan)
      }
    },
    latitude: {
      get: function() {
        return this.center[0]
      },
      set: function(v) {
        this.$bus.$emit('mapCenter', 'planMap', [v, this.center[1]], this.zoom)
      }
    },
    longitude: {
      get: function() {
        return this.center[1]
      },
      set: function(v) {
        this.$bus.$emit('mapCenter', 'planMap', [this.center[0], v], this.zoom)
      }
    },
    zoomcp: {
      get: function() {
        return parseFloat(this.zoom)
      },
      set: function(v) {
        this.$bus.$emit('mapCenter', 'planMap', this.center, parseFloat(v))
      }
    }
  },
  watch: {
    editMode() {
      this.activePlan.id !== 'new' && this.centerMap(this.activePlan)
    }
  }
}
</script>
